* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  list-style: none !important;
}

body,
.testimonial-slide,
.MuiTypography-root {
  font-family: "Clash Display", sans-serif !important;
}

body {
  background: #fff;
  /* height: 100vh; */
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}
.MuiButton-root {
  font-family: "Clash Display", sans-serif !important;
}
html {
  height: 100%;
}
.contsnt h1 {
  font-size: 67px;
  color: #fff;
  /* font-family: var(--main-Bold); */
}
.contsnt h1 span {
  background-image: var(--title-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.contsnt p {
  margin: 0;
  color: #888b90;
  font-size: 19px;
  /* font-family: var(--main-Medium); */
}
.contsnt small {
  font-size: 16px;
  color: #888b90;
  margin-top: 13px;
  display: block;
  /* font-family: var(--main-Medium); */
}
header {
  border-bottom: 1px solid #121212 !important;
  background: hsla(0, 0%, 5%, 0.005) !important;
  -webkit-backdrop-filter: blur(24px) !important;
  backdrop-filter: blur(24px) !important;
  border-bottom: 0.5px solid #121212 !important;
}
.contsnt {
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
button.primery_color,
button.primery_color:hover {
  color: #fff;
  padding: 11px 34px;
  width: fit-content;
  text-transform: capitalize;
  margin-top: 22px;
  background: linear-gradient(190deg, #ffda99, #f4663e 70%) !important;
}
.nav_link_section a {
  font-size: 16px;
  color: #1a1a1a;
  padding: 0px 14px;
  /* font-family: var(--main-Medium); */
}

.dark .nav_link_section a {
  color: #fff;
}
.nav_link_section {
  margin: 0 auto;
}
.header_btn {
  /* font-family: var(--main-Medium); */

  text-transform: capitalize !important;
  padding: 8px 15px !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  background: linear-gradient(190deg, #ffda99, #f4663e 70%) !important;
  color: #fff !important;
}
.header_btn:hover {
  font-weight: 600 !important;
  text-transform: capitalize !important;
  padding: 8px 15px !important;
  box-shadow: none !important;
  border-radius: 5px !important;
  background: linear-gradient(190deg, #ffda99, #f4663e 70%) !important;
  color: #fff !important;
}
.header_padidng {
  padding: 5px 0px;
  position: fixed !important;
  border: none !important;
  box-shadow: none !important;
  z-index: 100000 !important;
  padding-top: 0;
  background-image: #fff !important;
}
.imag img {
  width: 160px;
}
.banner_bg {
  background-image: url(./Image/stars-second.svg);
  /* background-color: #000; */
  /* height: 100vh; */
  padding: 30px 0px;
  background-size: cover;
}
.secode_beee {
  background-image: url(./Image/gold_filter.png);
  background-size: cover;
  /* height: 100vh; */
  position: relative;
}
.secode_beee::before {
  background-image: url(./Image/slider_coin.png);
  content: "";
  height: 200px;
  width: 200px;
  position: absolute;
  position: absolute;
  background-size: 100% 100%;
  top: 71px;
  right: 0;
  animation: float 6s ease-in-out infinite;
}
.secode_beee::after {
  background-image: url(./Image/slider_coin.png);
  content: "";
  height: 200px;
  width: 200px;
  position: absolute;
  background-size: 100% 100%;
  bottom: 40px;
  left: 0;
  animation: float 6s ease-in-out infinite;
}
.banner_content {
  position: relative;
  z-index: 555;
  margin: 30vh 0px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 0;
}
.banner_content span {
  font-size: 40px;
  color: #ffeb3c;
  font-weight: 800;
}
.banner_content h1 {
  /* font-family: "Jost", sans-serif !important; */
  color: #ffeb3c;
  font-size: 131px;
  /* line-height: 59px; */
  margin-bottom: 25px;
  /* margin-top: -44px; */
  background-image: linear-gradient(
    to right,
    #462523 0,
    #cb9b51 22%,
    #f6e27a 45%,
    #f6f2c0 50%,
    #f6e27a 55%,
    #cb9b51 78%,
    #462523 100%
  );
  color: transparent;
  -webkit-background-clip: text;
}
.conatnt_seciotnt {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.conatnt_seciotnt > div {
  color: #fff;
  width: 80px;
  font-size: 68px;
  height: 80px;
  display: grid;
  place-content: center;
  border: 4px solid #ffeb3c;
  border-radius: 9px;
  margin: 22px 5px;
  margin-top: 12px;
}
.conatnt_seciotnt > div:first-child {
  margin-left: 0;
}
.banner_content button {
  font-size: 20px !important;
  padding: 5px 36px !important;
  margin-top: 10px;
}
.banner_ing {
  position: relative;
  height: 501px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  margin: 134px 0px;
  margin-bottom: 0;
}
.banner_ing img {
  width: 100%;
  margin: 0 auto;
  display: block;
  /* margin-bottom: -126px; */
}
.enterer {
  color: #ffeb3c;
  position: absolute;
  bottom: -55px;
  font-size: 83px;
  font-weight: 600;
  transform: rotate(-12deg);
}
.banner_content p {
  text-align: left;
  color: #fff;
  font-size: 20px;
}
.section_secode_bg {
  background-color: transparent;
  padding: 60px 0px;
  position: relative;
  background-image: url(./Image/stars-second.svg);
  background-size: cover;
  padding-bottom: 0;
  background-attachment: fixed;
}
.section_secode_bg::before {
  position: absolute;
  content: "";
  background-image: url(./Image/Orange.png);
  height: 500px;
  width: 500px;
  background-repeat: no-repeat;
  background-size: contain;
  top: 0px;
  -webkit-animation: blink-2 1.5s infinite both;
  animation: blink-2 1.5s infinite both;
}
/* .section_secode_bg::after {
  position: absolute;
  content: "";
  background-image: url(./Image/bro.png);
  height: 318px;
  width: 604px;
  background-repeat: no-repeat;
  background-size: cover;
  bottom: 0;
  right: 0;
} */
.blink {
  animation: blink 0.5s linear infinite;
}
.image_coin {
  margin: 0 !important;
  position: absolute;
  width: 81px !important;
  top: 33px;
  left: 128px;
  animation: float 4s ease-in-out infinite;
}
.right {
  right: 55px;
  left: auto;
  top: 115px;
}
.tracking-in-expand {
  -webkit-animation: tracking-in-expand 1s cubic-bezier(0.215, 0.61, 0.355, 1)
    both;
  animation: tracking-in-expand 1s cubic-bezier(0.215, 0.61, 0.355, 1) both;
}
@-webkit-keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}
@keyframes tracking-in-expand {
  0% {
    letter-spacing: -0.5em;
    opacity: 0;
  }
  40% {
    opacity: 0.6;
  }
  100% {
    opacity: 1;
  }
}

.bounce-top {
  -webkit-animation: bounce-top 0.9s both;
  animation: bounce-top 0.9s both;
}

@-webkit-keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}
@keyframes bounce-top {
  0% {
    -webkit-transform: translateY(-45px);
    transform: translateY(-45px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
    opacity: 1;
  }
  24% {
    opacity: 1;
  }
  40% {
    -webkit-transform: translateY(-24px);
    transform: translateY(-24px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  65% {
    -webkit-transform: translateY(-12px);
    transform: translateY(-12px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  82% {
    -webkit-transform: translateY(-6px);
    transform: translateY(-6px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  93% {
    -webkit-transform: translateY(-4px);
    transform: translateY(-4px);
    -webkit-animation-timing-function: ease-in;
    animation-timing-function: ease-in;
  }
  25%,
  55%,
  75%,
  87% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
  }
  100% {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-animation-timing-function: ease-out;
    animation-timing-function: ease-out;
    opacity: 1;
  }
}

.wobble-hor-bottom {
  -webkit-animation: wobble-hor-bottom 1.5s infinite both;
  animation: wobble-hor-bottom 1.5s infinite both;
}

@-webkit-keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}
@keyframes wobble-hor-bottom {
  0%,
  100% {
    -webkit-transform: translateX(0%);
    transform: translateX(0%);
    -webkit-transform-origin: 50% 50%;
    transform-origin: 50% 50%;
  }
  15% {
    -webkit-transform: translateX(-30px) rotate(-6deg);
    transform: translateX(-30px) rotate(-6deg);
  }
  30% {
    -webkit-transform: translateX(15px) rotate(6deg);
    transform: translateX(15px) rotate(6deg);
  }
  45% {
    -webkit-transform: translateX(-15px) rotate(-3.6deg);
    transform: translateX(-15px) rotate(-3.6deg);
  }
  60% {
    -webkit-transform: translateX(9px) rotate(2.4deg);
    transform: translateX(9px) rotate(2.4deg);
  }
  75% {
    -webkit-transform: translateX(-6px) rotate(-1.2deg);
    transform: translateX(-6px) rotate(-1.2deg);
  }
}

.blink-1 {
  -webkit-animation: blink-1 1s infinite both;
  animation: blink-1 1s infinite both;
}
@-webkit-keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}
@keyframes blink-1 {
  0%,
  50%,
  100% {
    opacity: 1;
  }
  25%,
  75% {
    opacity: 0;
  }
}

.shake-horizontal {
  -webkit-animation: shake-horizontal 1.6s
    cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
  animation: shake-horizontal 1.6s cubic-bezier(0.455, 0.03, 0.515, 0.955) both;
}
@-webkit-keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}
@keyframes shake-horizontal {
  0%,
  100% {
    -webkit-transform: translateX(0);
    transform: translateX(0);
  }
  10%,
  30%,
  50%,
  70% {
    -webkit-transform: translateX(-10px);
    transform: translateX(-10px);
  }
  20%,
  40%,
  60% {
    -webkit-transform: translateX(10px);
    transform: translateX(10px);
  }
  80% {
    -webkit-transform: translateX(8px);
    transform: translateX(8px);
  }
  90% {
    -webkit-transform: translateX(-8px);
    transform: translateX(-8px);
  }
}

@keyframes blink {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}
.scale {
  width: 100%;
  transform: scale(0.9);
}
.zoome {
  animation: zoome 2s linear infinite;
}
@keyframes zoome {
  0% {
    transform: scale(0.7);
  }
  25% {
    transform: scale(0.9);
  }
  50% {
    transform: scale(1.1);
  }
  75% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(0.7);
  }
}

.second_ticket_content {
  padding: 6px 0px;
}
.second_ticket_content h2 {
  font-size: 52px;
  color: #ffeb3c;
  font-weight: 800;
  margin-bottom: 20px;
  text-align: center;
}
.second_ticket_content p {
  color: #fff;
  font-size: 30px;
  font-weight: 700;
  text-align: center;
}
.second_ticket_content button.header_btn {
  padding: 8px 30px !important;
  font-weight: 700 !important;
  font-size: 16px;
  margin: 0 auto;
  display: block;
  margin-top: 30px !important;
}
.price_table {
  background: #000;
  border-radius: 20px;
  overflow: hidden;
  margin: 50px 50px;
}
.price_row {
  display: flex;
  border-bottom: 1px solid #ccc6;
  position: relative;
}
.price_col {
  width: 50%;
  display: grid;
  place-content: center;
  padding: 15px 0px;
}
.price_col p {
  margin: 0;
  font-weight: 800;
  color: #999;
  font-size: 19px;
}
.colwhite {
  color: #fff !important;
}
.bor_right {
  position: relative;
}
.bor_right::after {
  position: absolute;
  content: "";
  height: 46px;
  width: 1px;
  background-color: #ccc6;
  right: 0;
  top: 7px;
}
.inner_row {
  display: flex;
}
.inner_row .inner_col {
  width: 50%;
  display: grid;
  place-content: center;
  background: #212121;
  padding: 15px 0px;
}
.inner_row span {
  color: #fff;
  font-size: 19px;
}
.bordr-non {
  border: none;
}
/* .inner_section {
  background-image: url(./Image/inner_bg_1.png);
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
} */
.buy_ticket_section h2 {
  font-size: 52px;
  color: #ffeb3c;
  font-weight: 800;
  text-align: center;
  margin-bottom: 20px;
}
.buy_ticket_section .card_buy_ticket {
  background: #000;
  border-radius: 10px;
  padding: 0px 0px;
  border: 5px solid #ffeb3c;
}
.buy_ticket_section .card_buy_ticket .card_header {
  margin-bottom: 10px;
}
.card_buy_ticket {
  margin-top: 40px;
}
.buy_ticket_section .card_buy_ticket .card_header .flex_header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #ffeb3c;
  border-radius: 0;
  padding: 9px 10px;
}
.buy_ticket_section .card_buy_ticket .card_header .flex_header h4 {
  margin: 0;
  color: #000;
  font-weight: 700;
  font-size: 20px;
  padding: 10px 0px;
}
.buy_ticket_section .card_buy_ticket .card_header .flex_header span {
  color: #000;
  font-weight: 500;
  font-size: 15px;
}
.card_body_ {
  padding: 40px 0px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.card_body_ span {
  color: #fff;
  font-weight: 800;
  font-size: 25px;
  margin-bottom: 10px;
}
.card_body_ h5 {
  margin: 0;
  color: #ffeb3c;
  font-weight: 800;
  font-size: 45px;
  margin-bottom: 20px;
}
.card_body_ > button {
  border-radius: 5px !important;
  font-weight: 800 !important;
  /* margin-right: 10px !important; */
}
.inner_section_2 {
  /* background-image: url(./Image/inner_bg_2.png); */
  background-size: cover;
  background-position: right;
  background-repeat: no-repeat;
  padding: 173px 0px;
  position: relative;
}
.inner_section_2::before {
  position: absolute;
  content: "";
  background-image: url(./Image/slider_coin.png);
  height: 200px;
  width: 200px;
  position: absolute;
  background-size: 100% 100%;
  top: 40px;
  animation: float 6s ease-in-out infinite;
  left: 0;
}
.winnwrr_list h2 {
  font-size: 52px;
  color: #ffeb3c;
  font-weight: 800;
  margin-bottom: 40px;
  text-align: center;
}
.winnwrr_list .card_table {
  background: #000;
  padding: 20px 0px;
  border: 5px solid #ffeb3c;
  border-radius: 10px;
  padding-top: 0;
}
.winnwrr_list .card_table .nav-tabs {
  border-bottom: 1px solid #efefef38;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #ffeb3c;
}
.winnwrr_list .tab-content {
  padding: 0px 25px;
}
.winnwrr_list .card_table .nav-tabs li {
  display: flex;
}
.winnwrr_list .card_table .nav-tabs li a {
  padding: 15px 20px;
  text-align: center;
  color: #525254;
  text-decoration: none;
  margin-bottom: -1px;
  font-weight: 600;
}
.winnwrr_list .card_table .nav-tabs li a.active {
  color: #000;
  border-bottom: 5px solid #000;
}
.table thead th {
  vertical-align: bottom;
  border-bottom: 0px solid #dee2e6 !important;
  color: #6c7080;
}
.table td,
.table th {
  padding: 17px 0px;
  vertical-align: top;
  border-top: 1px solid #dee2e63d !important;
}
.table td {
  color: #fff;
}
.three_section {
  padding: 110px 0px;
  background-image: url(./Image/stars-second.svg);
  background-size: cover;
  padding-top: 0;
}
.four_section {
  background-size: cover;
  background-image: url(./Image/stars-second.svg);
}

#main {
  margin: 50px 0;
}

#main #faq .card {
  margin-bottom: 14px;
  border: 0;
  background: transparent;
  overflow: hidden;
}

#main #faq .card .card-header {
  border: 0;
  -webkit-box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  box-shadow: 0 0 20px 0 rgba(213, 213, 213, 0.5);
  border-radius: 2px;
  padding: 0;
}

#main #faq .card .card-header .btn-header-link {
  color: #fff;
  display: block;
  text-align: left;
  background: #302f2f;
  color: #fff;
  padding: 20px;
}

#main #faq .card .card-header .btn-header-link:after {
  content: "\f107";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  float: right;
}

#main #faq .card .card-header .btn-header-link.collapsed {
  background: #302f2f;
  color: #fff;
}

#main #faq .card .card-header .btn-header-link.collapsed:after {
  content: "\f106";
}

#main #faq .card .collapsing {
  background: #302f2f;
  line-height: 30px;
  color: #fff;
}

#main #faq .card .collapse {
  border: 0;
}

#main #faq .card .collapse.show {
  background: #302f2f;
  line-height: 30px;
  color: #fff;
}

.Faq_section h2 {
  font-size: 52px;
  color: #ffeb3c;
  font-weight: 800;
  margin-bottom: 40px;
}
.four_section {
  padding: 100px 0px;
}
.img_faq {
  padding: 136px 0px;
}
.img_faq img {
  margin: 0 auto;
  display: block;
  width: 80%;
}
.logo_section_f img {
  width: 165px;
  margin-bottom: 10px;
}
.logo_section_f p {
  color: #444746;
  /* font-family: var(--main-Medium); */
  font-size: 17px;
}
.link_section h4 {
  color: #000;
  font-size: 22px;
  margin-bottom: 15px;
  /* font-family: var(--main-Medium); */
}
.link_section ul {
  list-style: none;
}
.link_section ul a {
  color: #444746;
  text-decoration: none;
  padding: 7px 0px;
  /* font-family: var(--main-Medium); */
  display: block;
}
footer {
  padding: 50px 0px;
  background: #f9f9fa;
  padding-bottom: 0;
}
.email_form {
  display: flex;
  justify-content: center;
  align-items: center;
  background: #535252;
  margin-bottom: 11px;
}
.email_form input {
  flex-grow: 1;
  background: #f8f8f9;
  border: none;
  color: #000;
  height: 50px;
  padding: 0px 10px;
}
.email_form button {
  background: linear-gradient(190deg, #ffda99, #f4663e 70%) !important;
  height: 50px;
  padding: 0px 11px;
  font-size: 15px;
  margin: 0;
  /* font-family: var(--main-Medium); */
  border: none;
  color: #fff;
}
.note_footer {
  color: #999;
}
.social {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
}
.social a {
  color: #1f1f1f;
  font-size: 24px;
  padding: 0px 20px;
}
.border-s {
  border-color: #fff !important;
}
.social a:first-child {
  padding-left: 0;
}
.border-s img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
    contrast(103%);
}
.copywrigt p {
  margin: 0;
  color: #656d76;
  text-align: center;
  /* font-family: "ProductSans_Light"; */
}
.copywrigt {
  padding: 15px 0px;
  margin-top: 15px;
  border-top: 1px solid #66656542;
}
.modal {
  z-index: 50000000;
}
.modal-content {
  background-color: #302f2f;
}
.modal-title {
  color: #fff;
}
.modal-header .close {
  color: #fff;
}
.modal-header {
  border-bottom: 1px solid #dee2e633;
}
.input_form_tivket {
  padding: 10px 0px;
}
.input_form_tivket p {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #999;
  margin-bottom: 7px;
  padding: 0px 5px;
}
.input_form_tivket p span {
  font-weight: 800;
  color: #fff;
}
.input_form_tivket input {
  width: 100%;
  height: 61px;
  border-radius: 10px;
  border: none;
  background: #000;
  color: #fff;
  font-size: 30px;
  font-weight: 600;
  padding: 0px 5px;
  text-align: right;
}
.input_form_tivket .amount_bust {
  color: #fff;
  text-align: right;
  padding: 9px 5px;
}
.amount_content {
  padding: 0px 7px;
}
.amount_content p {
  margin: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #a0a0a0;
  margin-bottom: 10px;
}
.amount_content p span {
  font-weight: 800;
  color: #fff;
}
.youpay {
  padding: 5px 0px;
  border-top: 1px solid #696969;
  margin-bottom: 20px;
}
.ticket_section button.header_btn {
  width: 100%;
  border-radius: 10px !important;
  padding: 9px 0px !important;
  font-size: 17px;
  font-weight: 800;
}
.wallet_option {
  margin: 0px 20px;
  cursor: pointer;
  padding: 20px 0px;
}
.wallet_option:hover {
  background: #000;
  border-radius: 10px;
}
.wallet_option img {
  width: 50px;
  display: block;
  margin: 0 auto;
  margin-bottom: 10px;
}
.wallet_option p {
  margin: 0;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 18px;
}
.banner_img {
  min-height: 650px;
  max-height: 650px;
}
.banner_img img {
  object-fit: cover;
  background-size: cover;
  width: 100%;
}

.slick-prev {
  left: 25px !important;
  z-index: 555 !important;
}
.slick-next {
  right: 15px !important;
}
.slick-next:before {
  content: "\f054" !important;
}
.slick-prev:before,
.slick-next:before {
  font-family: "FontAwesome" !important;
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color: white;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.slick-prev:before {
  content: "\f053" !important;
}
.slick-prev,
.slick-next {
  width: 40px !important;
  height: 40px !important;
  background: #302f2f !important;
  border-radius: 38px !important;
}
.refral_contene {
  padding: 50px 0px;
}
.refral_contene h1 {
  color: #fff;
  font-weight: 700;
  margin-bottom: 19px;
}
.refral_contene p {
  color: #999;
  font-size: 19px;
}
.referal_section {
  padding: 100px 0px;
}
.referal_card {
  padding: 20px;
  background: #302f2f;
  border-radius: 10px;
  margin: 40px 0px;
}
.tilet_refer {
  margin-bottom: 14px;
}
.tilet_refer span {
  font-weight: 700;
  color: #fff;
  font-size: 25px;
}
.refer_linlk_ss .input_card_refer {
  display: flex;
  width: 90%;
  justify-content: center;
  align-items: center;
  background: #000;
  border-radius: 10px;
  overflow: hidden;
}
.refer_linlk_ss .input_card_refer input {
  flex-grow: 1;
  height: 50px;
  background: transparent;
  border: none;
  color: #fff;
  padding: 0px 10px;
}
.refer_linlk_ss .input_card_refer i {
  color: #fff;
  padding: 0px 15px;
}
.refer_linlk_ss {
  display: flex;
  justify-content: center;
  align-items: center;
}
.refer_linlk_ss button {
  height: 50px;
  color: #fff;
  background: #000;
  border-radius: 10px;
  margin-left: 8px;
}
.referal_inner_card {
  padding: 11px 11px;
  background: #000;
  margin: 20px 0px;
  border-radius: 13px;
}
.referal_inner_card h3 {
  color: #fff;
  font-size: 18px;
  font-weight: 700;
}
.referal_inner_card p {
  margin: 0;
  font-weight: 900;
  color: #ffeb3c;
  font-size: 34px;
}
.lotery_titlt {
  color: #fff;
  font-size: 33px;
  margin-bottom: 39px;
}
.bg-non {
  background-image: none !important;
}
.card_how_to {
  padding: 35px 27px;
  background: #302f2f;
  border-radius: 20px;
  position: relative;
  margin: 50px 0px;
}
.card_how_to span {
  position: absolute;
  right: -14px;
  font-size: 29px;
  font-weight: 700;
  color: #000;
  background: #ffeb3c;
  padding: 0px 14px;
  width: 50px;
  height: 50px;
  display: grid;
  place-content: center;
  border-radius: 50%;
  top: -25px;
}
.card_how_to h3 {
  color: #fff;
  font-weight: 700;
  font-size: 29px;
}
.card_how_to p {
  margin: 0;
  color: #999;
}
.card_section_new {
  background: #302f2f;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 30%;
  margin: 0px 20px;
  padding: 14px 0px;
  transition-duration: 0.3s;
  cursor: pointer;
}
.card_section_new:hover {
  border-top: 10px solid #ffeb3c;
  margin-top: -9px;
}
.card_section_new img {
  width: 57px;
  margin-right: 14px;
  filter: invert(92%) sepia(89%) saturate(1325%) hue-rotate(325deg)
    brightness(110%) contrast(102%);
}
.steep {
  margin-top: -85px;
}
.card_section_new p {
  margin: 0;
  color: #fff;
  font-weight: 700;
}
.card_ster {
  display: flex;
  justify-content: center;
  align-items: center;
}
.heade_hoe p {
  color: #fff;
  font-size: 20px;
}
.heade_hoe h2 {
  font-size: 52px;
  color: #ffeb3c;
  font-weight: 800;
  margin-bottom: 22px;
  text-align: center;
}

.posiotne {
  position: relative;
  padding-top: 0;
}
.animation_section {
  position: absolute;
  width: 100%;
}
.heade_hoe p {
  color: #fff;
  font-size: 20px;
  text-align: center;
}
.howtostep {
  background-image: url(./Image/stars-second.svg);
  background-size: cover;
  background-color: transparent;
}
.purple {
  -webkit-animation: #ffeb3c linear 30s alternate infinite;
  animation: #ffeb3c linear 30s alternate infinite;
  border: 2px solid #241379;
  border-radius: 100%;
  width: 34px;
  height: 34px;
  transform: translate3d(28vw, 27vh, 0);
  z-index: 7;
}

.purple::before {
  -webkit-animation: purple-pseudo linear 15s alternate infinite;
  animation: purple-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 20px;
  height: 19px;
  transform: translate3d(-17vw, -11vh, 0) rotate(353deg);
}

.purple::after {
  -webkit-animation: purple-pseudo linear 20s alternate infinite;
  animation: purple-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 18px;
  height: 45px;
  transform: translate3d(59vw, 12vh, 0) rotate(111deg);
}

@-webkit-keyframes purple {
  50% {
    transform: translate3d(47vw, 100vh, 0);
  }
  100% {
    transform: translate3d(9vw, 41vh, 0);
  }
}

@keyframes purple {
  50% {
    transform: translate3d(47vw, 100vh, 0);
  }
  100% {
    transform: translate3d(9vw, 41vh, 0);
  }
}
@-webkit-keyframes purple-pseudo {
  33% {
    transform: translate3d(-45vw, -33vh, 0) rotate(280deg);
  }
  100% {
    transform: translate3d(62vw, 52vh, 0) rotate(123deg);
  }
}
@keyframes purple-pseudo {
  33% {
    transform: translate3d(-45vw, -33vh, 0) rotate(280deg);
  }
  100% {
    transform: translate3d(62vw, 52vh, 0) rotate(123deg);
  }
}
.medium-blue {
  -webkit-animation: medium-blue linear 30s alternate infinite;
  animation: medium-blue linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 42px;
  height: 42px;
  transform: translate3d(71vw, 13vh, 0);
  z-index: 5;
}

.medium-blue::before {
  -webkit-animation: medium-blue-pseudo linear 15s alternate infinite;
  animation: medium-blue-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 46px;
  height: 26px;
  transform: translate3d(-50vw, 67vh, 0) rotate(352deg);
}

.medium-blue::after {
  -webkit-animation: medium-blue-pseudo linear 20s alternate infinite;
  animation: medium-blue-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 15px;
  height: 9px;
  transform: translate3d(-51vw, 24vh, 0) rotate(88deg);
}

@-webkit-keyframes medium-blue {
  50% {
    transform: translate3d(26vw, 80vh, 0);
  }
  100% {
    transform: translate3d(50vw, 46vh, 0);
  }
}

@keyframes medium-blue {
  50% {
    transform: translate3d(26vw, 80vh, 0);
  }
  100% {
    transform: translate3d(50vw, 46vh, 0);
  }
}
@-webkit-keyframes medium-blue-pseudo {
  33% {
    transform: translate3d(68vw, 19vh, 0) rotate(23deg);
  }
  100% {
    transform: translate3d(-21vw, -12vh, 0) rotate(254deg);
  }
}
@keyframes medium-blue-pseudo {
  33% {
    transform: translate3d(68vw, 19vh, 0) rotate(23deg);
  }
  100% {
    transform: translate3d(-21vw, -12vh, 0) rotate(254deg);
  }
}
.light-blue {
  -webkit-animation: light-blue linear 30s alternate infinite;
  animation: light-blue linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 6px;
  height: 6px;
  transform: translate3d(82vw, 71vh, 0);
  z-index: 3;
}

.light-blue::before {
  -webkit-animation: light-blue-pseudo linear 15s alternate infinite;
  animation: light-blue-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 25px;
  height: 47px;
  transform: translate3d(-60vw, -37vh, 0) rotate(38deg);
}

.light-blue::after {
  -webkit-animation: light-blue-pseudo linear 20s alternate infinite;
  animation: light-blue-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 30px;
  height: 48px;
  transform: translate3d(-53vw, -34vh, 0) rotate(154deg);
}

@-webkit-keyframes light-blue {
  50% {
    transform: translate3d(89vw, 97vh, 0);
  }
  100% {
    transform: translate3d(93vw, 88vh, 0);
  }
}

@keyframes light-blue {
  50% {
    transform: translate3d(89vw, 97vh, 0);
  }
  100% {
    transform: translate3d(93vw, 88vh, 0);
  }
}
@-webkit-keyframes light-blue-pseudo {
  33% {
    transform: translate3d(-58vw, 0vh, 0) rotate(70deg);
  }
  100% {
    transform: translate3d(-3vw, -88vh, 0) rotate(283deg);
  }
}
@keyframes light-blue-pseudo {
  33% {
    transform: translate3d(-58vw, 0vh, 0) rotate(70deg);
  }
  100% {
    transform: translate3d(-3vw, -88vh, 0) rotate(283deg);
  }
}
.red {
  -webkit-animation: #ffeb3c linear 30s alternate infinite;
  animation: #ffeb3c linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 34px;
  height: 34px;
  transform: translate3d(41vw, 23vh, 0);
  z-index: 6;
}

.red::before {
  -webkit-animation: red-pseudo linear 15s alternate infinite;
  animation: red-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 44px;
  height: 47px;
  transform: translate3d(-11vw, 31vh, 0) rotate(117deg);
}

.red::after {
  -webkit-animation: red-pseudo linear 20s alternate infinite;
  animation: red-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 46px;
  height: 21px;
  transform: translate3d(10vw, 63vh, 0) rotate(127deg);
}

@-webkit-keyframes red {
  50% {
    transform: translate3d(89vw, 83vh, 0);
  }
  100% {
    transform: translate3d(32vw, 58vh, 0);
  }
}

@keyframes red {
  50% {
    transform: translate3d(89vw, 83vh, 0);
  }
  100% {
    transform: translate3d(32vw, 58vh, 0);
  }
}
@-webkit-keyframes red-pseudo {
  33% {
    transform: translate3d(-39vw, -80vh, 0) rotate(102deg);
  }
  100% {
    transform: translate3d(-18vw, -22vh, 0) rotate(284deg);
  }
}
@keyframes red-pseudo {
  33% {
    transform: translate3d(-39vw, -80vh, 0) rotate(102deg);
  }
  100% {
    transform: translate3d(-18vw, -22vh, 0) rotate(284deg);
  }
}
.orange {
  -webkit-animation: #ffeb3c linear 30s alternate infinite;
  animation: #ffeb3c linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 22px;
  height: 22px;
  transform: translate3d(97vw, 4vh, 0);
  z-index: 9;
}

.orange::before {
  -webkit-animation: orange-pseudo linear 15s alternate infinite;
  animation: orange-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 5px;
  height: 49px;
  transform: translate3d(-47vw, 64vh, 0) rotate(72deg);
}

.orange::after {
  -webkit-animation: orange-pseudo linear 20s alternate infinite;
  animation: orange-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 14px;
  height: 5px;
  transform: translate3d(-11vw, 4vh, 0) rotate(15deg);
}
.dark .marketplace_bg {
  background-image: url("./Image/banner/dark_marketplace.webp") !important;
}

@-webkit-keyframes orange {
  50% {
    transform: translate3d(28vw, 78vh, 0);
  }
  100% {
    transform: translate3d(100vw, 83vh, 0);
  }
}

@keyframes orange {
  50% {
    transform: translate3d(28vw, 78vh, 0);
  }
  100% {
    transform: translate3d(100vw, 83vh, 0);
  }
}
@-webkit-keyframes orange-pseudo {
  33% {
    transform: translate3d(-26vw, -28vh, 0) rotate(321deg);
  }
  100% {
    transform: translate3d(-11vw, 4vh, 0) rotate(246deg);
  }
}
@keyframes orange-pseudo {
  33% {
    transform: translate3d(-26vw, -28vh, 0) rotate(321deg);
  }
  100% {
    transform: translate3d(-11vw, 4vh, 0) rotate(246deg);
  }
}
.yellow {
  -webkit-animation: #ffeb3c linear 30s alternate infinite;
  animation: #ffeb3c linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 16px;
  height: 16px;
  transform: translate3d(87vw, 8vh, 0);
  z-index: 9;
}

.yellow::before {
  -webkit-animation: yellow-pseudo linear 15s alternate infinite;
  animation: yellow-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 18px;
  height: 32px;
  transform: translate3d(-34vw, 47vh, 0) rotate(79deg);
}

.yellow::after {
  -webkit-animation: yellow-pseudo linear 20s alternate infinite;
  animation: yellow-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 9px;
  height: 22px;
  transform: translate3d(3vw, 27vh, 0) rotate(181deg);
}

@-webkit-keyframes yellow {
  50% {
    transform: translate3d(65vw, 7vh, 0);
  }
  100% {
    transform: translate3d(5vw, 78vh, 0);
  }
}

@keyframes yellow {
  50% {
    transform: translate3d(65vw, 7vh, 0);
  }
  100% {
    transform: translate3d(5vw, 78vh, 0);
  }
}
@-webkit-keyframes yellow-pseudo {
  33% {
    transform: translate3d(-56vw, 42vh, 0) rotate(321deg);
  }
  100% {
    transform: translate3d(88vw, 19vh, 0) rotate(196deg);
  }
}
@keyframes yellow-pseudo {
  33% {
    transform: translate3d(-56vw, 42vh, 0) rotate(321deg);
  }
  100% {
    transform: translate3d(88vw, 19vh, 0) rotate(196deg);
  }
}
.cyan {
  -webkit-animation: #ffeb3c linear 30s alternate infinite;
  animation: #ffeb3c linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 46px;
  height: 46px;
  transform: translate3d(14vw, 60vh, 0);
  z-index: 11;
}

.cyan::before {
  -webkit-animation: cyan-pseudo linear 15s alternate infinite;
  animation: cyan-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 6px;
  height: 25px;
  transform: translate3d(24vw, -43vh, 0) rotate(352deg);
}

.cyan::after {
  -webkit-animation: cyan-pseudo linear 20s alternate infinite;
  animation: cyan-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 25px;
  height: 11px;
  transform: translate3d(71vw, -55vh, 0) rotate(16deg);
}

@-webkit-keyframes cyan {
  50% {
    transform: translate3d(28vw, 73vh, 0);
  }
  100% {
    transform: translate3d(27vw, 64vh, 0);
  }
}

@keyframes cyan {
  50% {
    transform: translate3d(28vw, 73vh, 0);
  }
  100% {
    transform: translate3d(27vw, 64vh, 0);
  }
}
@-webkit-keyframes cyan-pseudo {
  33% {
    transform: translate3d(28vw, -53vh, 0) rotate(269deg);
  }
  100% {
    transform: translate3d(-9vw, 8vh, 0) rotate(135deg);
  }
}
@keyframes cyan-pseudo {
  33% {
    transform: translate3d(28vw, -53vh, 0) rotate(269deg);
  }
  100% {
    transform: translate3d(-9vw, 8vh, 0) rotate(135deg);
  }
}
.light-green {
  -webkit-animation: light-green linear 30s alternate infinite;
  animation: light-green linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 39px;
  height: 39px;
  transform: translate3d(31vw, 71vh, 0);
  z-index: 11;
}

.light-green::before {
  -webkit-animation: light-green-pseudo linear 15s alternate infinite;
  animation: light-green-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 24px;
  height: 34px;
  transform: translate3d(-20vw, 10vh, 0) rotate(90deg);
}

.light-green::after {
  -webkit-animation: light-green-pseudo linear 20s alternate infinite;
  animation: light-green-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 31px;
  height: 36px;
  transform: translate3d(27vw, -13vh, 0) rotate(197deg);
}

@-webkit-keyframes light-green {
  50% {
    transform: translate3d(63vw, 63vh, 0);
  }
  100% {
    transform: translate3d(55vw, 91vh, 0);
  }
}

@keyframes light-green {
  50% {
    transform: translate3d(63vw, 63vh, 0);
  }
  100% {
    transform: translate3d(55vw, 91vh, 0);
  }
}
@-webkit-keyframes light-green-pseudo {
  33% {
    transform: translate3d(-55vw, -28vh, 0) rotate(269deg);
  }
  100% {
    transform: translate3d(1vw, -47vh, 0) rotate(192deg);
  }
}
@keyframes light-green-pseudo {
  33% {
    transform: translate3d(-55vw, -28vh, 0) rotate(269deg);
  }
  100% {
    transform: translate3d(1vw, -47vh, 0) rotate(192deg);
  }
}
.lime {
  -webkit-animation: #ffeb3c linear 30s alternate infinite;
  animation: #ffeb3c linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 15px;
  height: 15px;
  transform: translate3d(7vw, 34vh, 0);
  z-index: 10;
}

.lime::before {
  -webkit-animation: lime-pseudo linear 15s alternate infinite;
  animation: lime-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 10px;
  height: 46px;
  transform: translate3d(20vw, 10vh, 0) rotate(23deg);
}

.lime::after {
  -webkit-animation: lime-pseudo linear 20s alternate infinite;
  animation: lime-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 40px;
  height: 24px;
  transform: translate3d(35vw, -22vh, 0) rotate(220deg);
}

@-webkit-keyframes lime {
  50% {
    transform: translate3d(67vw, 35vh, 0);
  }
  100% {
    transform: translate3d(18vw, 16vh, 0);
  }
}

@keyframes lime {
  50% {
    transform: translate3d(67vw, 35vh, 0);
  }
  100% {
    transform: translate3d(18vw, 16vh, 0);
  }
}
@-webkit-keyframes lime-pseudo {
  33% {
    transform: translate3d(-65vw, -10vh, 0) rotate(59deg);
  }
  100% {
    transform: translate3d(-6vw, -2vh, 0) rotate(210deg);
  }
}
@keyframes lime-pseudo {
  33% {
    transform: translate3d(-65vw, -10vh, 0) rotate(59deg);
  }
  100% {
    transform: translate3d(-6vw, -2vh, 0) rotate(210deg);
  }
}
.magenta {
  -webkit-animation: #ffeb3c linear 30s alternate infinite;
  animation: #ffeb3c linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 37px;
  height: 37px;
  transform: translate3d(10vw, 77vh, 0);
  z-index: 10;
}

.magenta::before {
  -webkit-animation: magenta-pseudo linear 15s alternate infinite;
  animation: magenta-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 31px;
  height: 47px;
  transform: translate3d(45vw, -53vh, 0) rotate(272deg);
}

.magenta::after {
  -webkit-animation: magenta-pseudo linear 20s alternate infinite;
  animation: magenta-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 39px;
  height: 19px;
  transform: translate3d(2vw, 0vh, 0) rotate(268deg);
}

@-webkit-keyframes magenta {
  50% {
    transform: translate3d(53vw, 39vh, 0);
  }
  100% {
    transform: translate3d(71vw, 45vh, 0);
  }
}

@keyframes magenta {
  50% {
    transform: translate3d(53vw, 39vh, 0);
  }
  100% {
    transform: translate3d(71vw, 45vh, 0);
  }
}
@-webkit-keyframes magenta-pseudo {
  33% {
    transform: translate3d(-25vw, 45vh, 0) rotate(351deg);
  }
  100% {
    transform: translate3d(4vw, -35vh, 0) rotate(186deg);
  }
}
@keyframes magenta-pseudo {
  33% {
    transform: translate3d(-25vw, 45vh, 0) rotate(351deg);
  }
  100% {
    transform: translate3d(4vw, -35vh, 0) rotate(186deg);
  }
}
.lightish-red {
  -webkit-animation: lightish-red linear 30s alternate infinite;
  animation: lightish-red linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 8px;
  height: 8px;
  transform: translate3d(66vw, 10vh, 0);
  z-index: 3;
}

.lightish-red::before {
  -webkit-animation: lightish-red-pseudo linear 15s alternate infinite;
  animation: lightish-red-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 44px;
  height: 20px;
  transform: translate3d(-13vw, -7vh, 0) rotate(342deg);
}

.lightish-red::after {
  -webkit-animation: lightish-red-pseudo linear 20s alternate infinite;
  animation: lightish-red-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 38px;
  height: 10px;
  transform: translate3d(-13vw, 54vh, 0) rotate(21deg);
}

@-webkit-keyframes lightish-red {
  50% {
    transform: translate3d(41vw, 78vh, 0);
  }
  100% {
    transform: translate3d(50vw, 12vh, 0);
  }
}

@keyframes lightish-red {
  50% {
    transform: translate3d(41vw, 78vh, 0);
  }
  100% {
    transform: translate3d(50vw, 12vh, 0);
  }
}
@-webkit-keyframes lightish-red-pseudo {
  33% {
    transform: translate3d(-5vw, -47vh, 0) rotate(83deg);
  }
  100% {
    transform: translate3d(24vw, -10vh, 0) rotate(250deg);
  }
}
@keyframes lightish-red-pseudo {
  33% {
    transform: translate3d(-5vw, -47vh, 0) rotate(83deg);
  }
  100% {
    transform: translate3d(24vw, -10vh, 0) rotate(250deg);
  }
}
.pink {
  -webkit-animation: #ffeb3c linear 30s alternate infinite;
  animation: #ffeb3c linear 30s alternate infinite;
  border: 2px solid #ffeb3c;
  border-radius: 100%;
  width: 12px;
  height: 12px;
  transform: translate3d(40vw, 11vh, 0);
  z-index: 11;
}

.pink::before {
  -webkit-animation: pink-pseudo linear 15s alternate infinite;
  animation: pink-pseudo linear 15s alternate infinite;
  background: #ffeb3c;
  border: 2px solid #ffeb3c;
  width: 13px;
  height: 45px;
  transform: translate3d(32vw, 76vh, 0) rotate(317deg);
}

.pink::after {
  -webkit-animation: pink-pseudo linear 20s alternate infinite;
  animation: pink-pseudo linear 20s alternate infinite;
  border: 2px solid #ffeb3c;
  width: 28px;
  height: 48px;
  transform: translate3d(50vw, 38vh, 0) rotate(38deg);
}

@-webkit-keyframes pink {
  50% {
    transform: translate3d(15vw, 29vh, 0);
  }
  100% {
    transform: translate3d(23vw, 22vh, 0);
  }
}

@keyframes pink {
  50% {
    transform: translate3d(15vw, 29vh, 0);
  }
  100% {
    transform: translate3d(23vw, 22vh, 0);
  }
}
@-webkit-keyframes pink-pseudo {
  33% {
    transform: translate3d(70vw, -26vh, 0) rotate(191deg);
  }
  100% {
    transform: translate3d(19vw, -1vh, 0) rotate(17deg);
  }
}
@keyframes pink-pseudo {
  33% {
    transform: translate3d(70vw, -26vh, 0) rotate(191deg);
  }
  100% {
    transform: translate3d(19vw, -1vh, 0) rotate(17deg);
  }
}

.animation_section {
  background: transparent;
  overflow: hidden;
  height: 100vh;
}
.animation_section:active div,
.animation_section body:active div::before,
.animation_section body:active div::after {
  padding: 40px;
}

.animation_section div,
.animation_section div::before,
.animation_section div::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.9;
  transform-origin: top;
  transition: all 5s linear 0s;
}

@keyframes float {
  0% {
    box-shadow: none;
    transform: translatey(0px);
  }
  50% {
    box-shadow: none;
    transform: translatey(-20px);
  }
  100% {
    box-shadow: none;
    transform: translatey(0px);
  }
}
.blink-2 {
  -webkit-animation: blink-2 1.5s infinite both;
  animation: blink-2 1.5s infinite both;
}
@-webkit-keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}
@keyframes blink-2 {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.2;
  }
  100% {
    opacity: 1;
  }
}

.card_winner_loter {
  border: 5px solid #ffeb3c;
  border-radius: 5px;
  margin-top: 35px;
}
.header_winner_card {
  padding: 10px 0px;
  text-align: center;
  font-weight: 700;
  color: #000;
  background: #ffeb3c;
}
.header_winner_card h4 {
  margin: 0;
  font-weight: 700;
  padding: 5px 0px;
}

.tilt-in-fwd-tl {
  -webkit-animation: tilt-in-fwd-tl 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94)
    both;
  animation: tilt-in-fwd-tl 0.6s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}
@-webkit-keyframes tilt-in-fwd-tl {
  0% {
    -webkit-transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px)
      skew(35deg, -10deg);
    transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px)
      skew(35deg, -10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}
@keyframes tilt-in-fwd-tl {
  0% {
    -webkit-transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px)
      skew(35deg, -10deg);
    transform: rotateY(-20deg) rotateX(35deg) translate(-300px, -300px)
      skew(35deg, -10deg);
    opacity: 0;
  }
  100% {
    -webkit-transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    transform: rotateY(0) rotateX(0deg) translate(0, 0) skew(0deg, 0deg);
    opacity: 1;
  }
}

.scale-in-ver-center {
  -webkit-animation: scale-in-ver-center 0.5s
    cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
  animation: scale-in-ver-center 0.5s cubic-bezier(0.25, 0.46, 0.45, 0.94) both;
}

@-webkit-keyframes scale-in-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
  }
}
@keyframes scale-in-ver-center {
  0% {
    -webkit-transform: scaleY(0);
    transform: scaleY(0);
    opacity: 1;
  }
  100% {
    -webkit-transform: scaleY(1);
    transform: scaleY(1);
    opacity: 1;
  }
}

html {
  height: auto;
  background: radial-gradient(ellipse at bottom, #1b2735 0%, #090a0f 100%);
  overflow: auto;
}

.croen {
  position: relative;
}
.croen img {
  position: absolute;
  width: 24px;
  top: 0px;
  left: -27px;
  transform: rotate(0deg);
}

/* new style launchpad */
.titte_section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.titte_section small {
  color: #fff;
  font-size: 25px;
  /* font-family: var(--main-Medium); */
}
.titte_section h2 {
  font-size: 44px;
  /* font-family: var(--main-Bold); */
  color: #fff;
  margin: 0;
  padding: 15px 0px;
  padding-top: 10px;
}
.titte_section p {
  color: #888b90;
  /* font-family: var(--main-Medium); */
  margin: 0;
}
.titte_section h2 span {
  background-image: var(--title-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.card_projece {
  background: rgba(255, 255, 255, 0.06);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 9%);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  border: 1px solid rgb(255 255 255 / 16%);
  overflow: hidden;
  margin: 40px 0px;
}
.card_projece .project_imag {
  position: relative;
  margin: 15px;
  border-radius: 10px;
  overflow: hidden;
}
.card_projece .network_icon {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 1px;
  border: 1px solid #fcb97f;
  border-radius: 22px;
}
.card_projece .network_icon img {
  width: 31px;
}
.project_name {
  display: flex;
  justify-content: flex-start;
  align-items: self-end;
  padding: 15px;
  margin-top: -54px;
  position: relative;
  z-index: 5;
}
.project_name .project_logo {
  padding: 0px;
  border: 1px solid #fcbf84;
  border-radius: 10px;
  margin-right: 10px;
  overflow: hidden;
}
.project_name .project_logo img {
  width: 60px;
}
.project_name h3 {
  color: #fff;
  font-size: 22px;
  /* font-family: var(--main-Medium); */
  margin: 0;
}
.project_detiles {
  padding: 15px;
  padding-top: 0;
}
.project_detiles p {
  margin: 0;
  color: #fff;
  /* font-family: var(--main-Medium); */
  margin: 0;
  font-size: 16px;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5px;
}
.project_detiles p span {
  background-image: var(--title-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.project_progress {
  padding: 15px;
  padding-top: 0px;
}
.project_progress p {
  margin: 0;
  color: #fff;
  /* font-family: var(--main-Medium); */
  margin: 0;
  font-size: 16px;
  margin-bottom: 5px;
}
.project_progress h4 {
  font-size: 20px;
  /* font-family: var(--main-Bold); */
  color: #fff;
  margin: 0;
  margin-bottom: 10px;
}
.project_progress .progress {
  border-radius: 10px;
  background: #2f2e2e;
}
.project_progress .progress .progress-bar {
  width: 50%;
  background: linear-gradient(190deg, #ffda99, #f4663e 70%) !important;
  border-radius: 13px;
}
.project_progress h4 span {
  background-image: var(--title-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-left: 5px;
}
.button_sectino {
  display: flex;
  justify-content: center;
  padding: 15px;
}
.button_sectino button {
  color: #fff;
  padding: 11px 34px;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: capitalize;
  margin-top: 0;
  background: linear-gradient(190deg, #ffda99, #f4663e 70%) !important;
  /* margin-bottom: 15px; */
}
.button_sectino button:hover {
  color: #fff;
  padding: 11px 34px;
  width: -moz-fit-content;
  width: fit-content;
  text-transform: capitalize;
  margin-top: 0;
  background: linear-gradient(190deg, #ffda99, #f4663e 70%) !important;
  /* margin-bottom: 15px; */
}
.section_style {
  padding: 50px 0px;
}
.secure_feture {
  padding: 20px;
  margin-top: 20px;
}
.secure_feture img {
  filter: invert(100%) sepia(100%) saturate(0%) hue-rotate(138deg)
    brightness(102%) contrast(103%);
  width: 51px;
  margin: 0 auto;
  display: block;
  margin-bottom: 16px;
}
.secure_feture h4 {
  color: #fff;
  font-size: 22px;
  /* font-family: var(--main-Medium); */
  margin-bottom: 10px;
  text-align: center;
}
.secure_feture p {
  color: #888b90;
  /* font-family: var(--main-Medium); */
  margin: 0;
  text-align: center;
}

.slick-slide > div {
  margin: 0 10px;
}
.slick-list {
  margin: 0 -10px;
}
.tokandetils .project_name {
  margin: 0;
  align-items: center;
}
.tokandetils .project_name p {
  color: #888b90;
  /* font-family: var(--main-Medium); */
  margin: 0;
}
.network_icon_deiv {
  display: flex;
  justify-content: center;
  align-items: center;
}
.network_icon_deiv .network_icon {
  padding: 1px;
  border: 1px solid #fcb97f;
  border-radius: 22px;
  width: fit-content;
}
.network_icon_deiv .network_icon img {
  width: 33px;
}
.exact_date p {
  color: #fff;
  /* font-family: var(--main-Medium); */
  margin: 0;
}
.completed_project {
  background: rgba(255, 255, 255, 0.06);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgb(0 0 0 / 9%);
  backdrop-filter: blur(4.3px);
  -webkit-backdrop-filter: blur(4.3px);
  border: 1px solid rgb(255 255 255 / 16%);
  overflow: hidden;
  margin: 40px 0px;
  padding: 15px 0px;
  transition: all 0.3s;
  margin-bottom: 0;
}
.completed_project .project_progress {
  width: 100%;
}
.completed_project:hover,
.card_projece:hover {
  cursor: pointer;
  border: 1px solid #f4663e;
}
.card_projece {
  transition: all 0.3s;
}
.colo_white {
  background: linear-gradient(106.19deg, #fffbfa, #fffdfa);
  margin-top: 25px;
  border-radius: 50px 50px 0px 0px;
  padding-top: 70px;
}
.scrolled .nav_link_section a {
  color: #000;
}
.header.scrolled > header {
  width: 100%;
  z-index: 999;
  /* background: hsla(0, 0%, 5%, 0.005);
  -webkit-backdrop-filter: blur(24px);
  backdrop-filter: blur(24px); */
  border-bottom: 0.5px solid;
  border-bottom: 0.5px solid #1212124f !important;
  box-shadow: none !important;
  background: #fff !important;
  z-index: 555 !important;
}

.header.scrolled .blackshow {
  display: none;
}
.header .whiteshow {
  display: block;
}
.header .blackshow {
  display: none;
}
/* Ai Doc page */

.new_Section_AIdoc h1 {
  /* font-size: 55px !important; */
  color: #1a1a1a;
  line-height: 70px !important;
}
.new_Section_AIdoc {
  background-color: #f3f5fc !important;
  align-items: flex-start !important;
  position: relative;
}

.new_Section_AIdoc::after {
  position: absolute;
  content: "";
  width: 50vw;
  height: 67vh;
  right: -40px;
  background-image: url("../src/Image/banner/text-editor_banner.webp");
  bottom: 0px;
  background-size: cover;
  background-repeat: no-repeat;
}
.cursor_point {
  cursor: pointer;
}
.section_banner_hero.new_Section_AIdoc {
  height: 90vh !important;
  min-height: 90vh !important;

  border-bottom-left-radius: 80px;
}
.section_banner_hero.new_Section_AIdoc.about_done {
  height: 80vh !important;
  min-height: 80vh !important;
  border-bottom-left-radius: 80px;
}
.new_AI_doc_card {
  position: relative;
}
.new_AI_doc_card::before {
  content: url("../src/Image/banner/spped.webp");
  display: block;
  position: absolute;
  bottom: 20px;
  /* left: 0; */
  width: fit-content;
  height: fit-content;
  right: 40px;
  z-index: 10;
}
.new_AI_doc_card.item2::before {
  content: "";
  width: 135px;
  height: 135px;
  background-image: url("../src/Image//banner/accuracy.webp");
  background-size: cover;
}
.new_AI_doc_card.item3::before {
  content: "";
  width: 135px;
  height: 135px;
  background-image: url("../src/Image//banner/customization.webp");
  background-size: cover;
}
.new_AI_doc_card.item4::before {
  content: "";
  width: 135px;
  height: 135px;
  background-image: url("../src/Image//banner/ui.webp");
  background-size: cover;
}
.new_AI_doc_card.item5::before {
  content: "";
  width: 135px;
  height: 135px;
  background-image: url("../src/Image//banner/compliance.webp");
  background-size: cover;
}
.new_AI_doc_card.item6::before {
  content: "";
  width: 135px;
  height: 135px;
  background-image: url("../src/Image//banner/artificial-intelligence.webp");
  background-size: cover;
}
.dar_directon {
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
}
.bg_car_pur {
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%) !important;
}
.bg_car_pur h3 {
  color: #fff;
}
.new_AI_doc_card p {
  line-height: 29px;
}
.bg_car_pur button {
  border-radius: 8px !important;
  background: #fff !important;
  color: #000 !important;
}
.width_60 {
  width: 60%;
}
.new_AI_doc_card .card_inside_content {
  z-index: 66;
  position: relative;
  padding-bottom: 50px;
}
.fetuer_section {
  display: flex;
  justify-content: space-between;
  margin-top: 10px;
}
.content_section_ {
  flex-grow: 1;
}
.half_width {
  width: 49%;
  display: flex;
  gap: 10px;
  justify-content: flex-start;
  align-items: flex-start;
  margin-bottom: 15px;
}
.fetuer_section .half_width h4 {
  font-size: 20px;
}
.fetuer_section .half_width span {
  width: 60px;
  height: 60px;
  background: #ccc;
  border-radius: 50%;
  display: grid;
  place-content: center;
  font-size: 26px;
  font-weight: 500;
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%) !important;
  color: #fff;
}
.fetuer_section .half_width p {
  text-align: start;
  font-size: 17px;
  margin-bottom: 0;
}
.new_Section_AIdoc .hero_seaction_law {
  justify-content: flex-start;
  padding-top: 170px;
  z-index: 1;
}
.border_radious_button {
  border-radius: 8px !important;
}
/* .background_row_ss {
  background-image: url("../src/Image/banner/simble_doc.png");
  background-size: 50% 50%;
  background-repeat: no-repeat;
  background-position: right center;
} */

.about_done::after {
  background-image: none !important;
}
.select_about_image {
  width: 70%;
  margin: 0 auto;
  display: block;
  margin-top: -190px;
  position: relative;
}
.card_about {
  padding: 80px 50px;
  background: #f3f5fc;
  border-radius: 20px;
}
.width_70 {
  width: 80%;
  position: relative;
}
.postion_imag_rel {
  position: absolute;
  right: -398px;
  width: 53%;
  top: -40px;
}
.new_Section_AIdoc.about_done {
  height: 550px !important;
  min-height: 550px !important;
  border-radius: 0 !important;
}
.new_Section_AIdoc .about_done::after {
  background-image: none !important;
  display: none;
}

.stats-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  /* max-width: 1200px; */
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%) !important;

  border-radius: 30px;
  padding: 20px;
}
.flec_row_ds {
  justify-content: space-between;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.features-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  max-width: 1200px;
  /* background-color: #e0e6ef; */
  border-radius: 15px;
  padding: 20px;
}
.circule_radioue {
  width: 60px;
  height: 60px;
  display: grid;
  place-content: center;
  border: 1px solid #6840e9;
  border-radius: 70px;
  margin-bottom: 10px;
}
.circule_radioue img {
  margin: 0 !important;
  width: 30px !important;
  height: 30px !important;
}
.feature-box {
  background-color: #f3f5fc;
  border-radius: 30px;
  color: #000;
  text-align: left;
  padding: 20px;
  margin: 10px;
  flex: 1;
  min-width: 200px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
}

.feature-box h3 {
  font-size: 1.5rem;
  margin: 0 0 10px;
}

.feature-box p {
  margin: 10px 0 0;
  font-size: 1rem;
}

.feature-box img {
  width: 40px;
  height: 40px;
  margin-bottom: 10px;
}

.stat-box {
  /* background-color: #6a0dad; */
  border-radius: 10px;
  color: #fff;
  text-align: center;
  padding: 20px;
  margin: 10px;
  flex: 1;
  min-width: 200px;
}

.stat-box h2 {
  font-size: 2rem;
  margin: 0;
}

.stat-box p {
  margin: 10px 0 0;
  font-size: 1rem;
}

.cards-container .card {
  background-color: #ffffff;
  border-radius: 20px;
  color: #000;
  text-align: left;
  padding: 10px 15px;
  width: 100%;
  box-shadow: none;
  display: flex;
  align-items: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  border: none;
  margin-top: 0px;
}
.flex-grow-s {
  flex-grow: 1;
}

.cards-container .card img {
  border-radius: 10px;
  margin-right: 20px;
  width: 80px;
  height: 80px;
}

.cards-container .card h3 {
  font-size: 1.5rem;
  margin: 0;
}

.cards-container .card p {
  margin: 5px 0 0;
  font-size: 1rem;
}
/* Ai Doc page */

/* upload docs */

.section_banner_hero.new_Section_AIdoc.uploaddocs {
  /* height: 100vh !important;
  min-height: 100vh !important; */
  border-bottom-left-radius: 0px;
}
.new_Section_AIdoc.uploaddocs::after {
  display: none;
}
.sapan_text {
  font-weight: 500;
  margin-bottom: 9px;
}

.keyfetupload-grid {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: space-between;
}

.keyfetupload-item {
  background-color: #f3f5fc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: none;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(50% - 0px);
  max-width: calc(50% - 10px);
  text-align: left;
}
.keyfetupload-item h2 {
  color: #5a67d8; /* Adjust the color according to the image */
  margin: 0;
  font-size: 2em;
}

.keyfetupload-item h3 {
  color: #000000; /* Black color for headings */
  font-size: 1.5em;
  margin: 10px 0;
}

.keyfetupload-item p {
  color: #6b6b6b; /* Grey color for text */
  font-size: 1em;
  line-height: 1.6em;
  margin: 0;
}

.upload-steps {
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* flex-wrap: wrap; */
  gap: 20px;
  width: 100%;
}

.upload-step {
  background-color: none;
  border-radius: 8;
  padding: 20px;
  box-shadow: none;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(23% - 0px);
  max-width: calc(25% - 0px);
  position: relative;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.upload-step .icon {
  width: 60px;
  height: 60px;
  background-color: #6b4ef4;
  border-radius: 50%;
  margin-bottom: 20px;
  position: relative;
  display: grid;
  place-items: center;
  font-size: 31px;
  color: #fff;
}
.upload-step p {
  color: #000000;
  font-size: 1em;
  line-height: 1.6em;
  margin: 0;
  /* max-width: 200px; */
}

/* Arrow icon using ::after */
.security_page .keyfetupload-item {
  background-color: transparent;
}
.security_page .keyfetupload-item h2 {
  width: fit-content;
  width: 60px;
  height: 60px;
  background: #6b4ef4;
  display: grid;
  place-content: center;
  border-radius: 50%;
  color: #fff;
}

.token-distribution {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  gap: 10px;
  justify-content: space-between;
  margin-top: 10px;
  flex-wrap: wrap;
  flex-direction: column;
}
.card_section_token p {
  margin: 0;
  font-size: 18px;
  font-weight: 500;
}
.card_section_token {
  border-radius: 10px !important;
}
.token-distribution li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
}

.dark .token-distribution li {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 18px;
  color: #fff;
}

.token-distribution li::before {
  content: "";
  display: inline-block;
  width: 12px;
  height: 12px;
  border-radius: 50%;
  margin-right: 8px;
}

/* Colors corresponding to each label */
.ecosystem::before {
  background-color: #a0522d; /* Sienna */
}

.founders::before {
  background-color: #8b4513; /* Saddle Brown */
}

.exchange-listing::before {
  background-color: #cd853f; /* Peru */
}

.contingency::before {
  background-color: #d2691e; /* Chocolate */
}

.private-sale::before {
  background-color: #8b4513; /* Saddle Brown */
}

.core-team::before {
  background-color: #5c4033; /* Dark Brown */
}
.card_clas_parner {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 10px;
  overflow: hidden;
  padding: 10px;
  min-height: 130px;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.partner-img-w {
  width: 65%;
  display: flex;
  margin: 0 auto;
  /* min-height: 162px; */
}
.custom-min-w {
  width: 70%;
}
.custom-min-width {
  width: 76%;
  margin: 0 auto;
}

.full-screen-bg {
  background-image: url("../src/Image/banner/roboAIbloxk.webp"); /* Dummy image URL */
  background-size: cover;
  background-position: center;
  width: 100%;
  height: 100%;
  position: relative;
  height: 100vh;
}

.full-screen-bg .overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Transparent black overlay */
  display: flex;
  align-items: center;
  justify-content: right;
}

.full-screen-bg .content {
  color: white;
  text-align: left;
  padding-right: 50px;
}

.full-screen-bg .content h1 {
  font-size: 4rem;
  margin-bottom: 20px;
}

.full-screen-bg .content p {
  font-size: 1.2rem;
  margin-bottom: 30px;
}

.full-screen-bg .btn-custom {
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%);
  color: #fff;
  padding: 10px 20px;
  width: fit-content;
  font-size: 1rem;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
}
.call_blog ul {
  list-style: none !important;
}
.call_blog strong {
  font-weight: 600 !important;
  margin-right: 10px !important;
}
/* upload docs */
.partner-content {
  flex-direction: column;
  min-height: 200px;
}
.partner-label {
  font-size: 18px;
  font-weight: 500;
  margin-top: 20px;
}
/* dark mode */
.dark .sapan_text {
  color: #fff;
}
.dark .color_white header {
  background: #161616 !important;
  /* border-bottom: 0.5px solid #1212124f !important; */
}
.dark .keyfetupload-item {
  background-color: #121212;
}
.dark .keyfetupload-item h3 {
  color: #fff;
}
.dark .keyfetupload-item p {
  color: #fff;
}
.dark .upload-step h4 {
  color: #fff;
}
.dark .upload-step p {
  color: #fff;
}
.dark .header .blackshow {
  display: block;
}
.dark .card_about {
  background: #121212;
}
.dark .feature-box {
  background-color: #121212;
}
.dark .new_Section_AIdoc {
  background-color: #121212 !important;
}
.dark .feature-box h3 {
  color: #fff;
}
.dark .feature-box p {
  color: #fff;
}

.dark .circule_radioue img {
  filter: invert(47%) sepia(3%) saturate(223%) hue-rotate(89deg)
    brightness(117%) contrast(80%);
}
.dark .cards-container .card {
  background-color: #161616;
}
.dark .new_AI_doc_card::before {
  opacity: 0.2;
}
.dark .cards-container .card h3 {
  color: #fff;
}
.dark .cards-container .card p {
  color: #fff;
}
.dark .fetuer_section .half_width h4 {
  color: #fff;
}
.header.scrolled .whiteshow {
  display: block;
}
.dark .header .whiteshow {
  display: none;
}
.content_white_first h3 {
  color: #000;
  /* font-family: var(--main-Bold); */
  font-size: 46px;
  margin-bottom: 30px;
}
.content_white_first h3 span {
  color: #f57348;
}
.dark .select_icon > svg {
  fill: #fff;
}
.content_white_first p {
  font-size: 19px;
  color: #000;
  /* font-family: var(--main-Medium); */
  margin: 0;
  margin-bottom: 25px;
}
.content_white_first button {
  background: #050505;
  color: #fff;
  text-transform: capitalize;
  font-size: 17px;
  padding: 10px 19px;
  margin-top: 10px;
}
.applay_sectoion {
  height: 100vh;
  background: #050505;
  border-radius: 50px;
}
.applay_sectoion {
  height: 70vh;
  background: #050505;
  border-radius: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.applay_sectoion h2 {
  /* font-family: var(--main-Bold); */
  font-size: 53px;
  color: #fff;
  text-align: center;
}
.applay_sectoion h2 span {
  background-image: var(--title-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.applay_sectoion button,
.applay_sectoion button:hover {
  background: #fff;
  text-transform: capitalize;
  color: #000;
  padding: 12px 37px;
  font-size: 18px;
  margin-top: 27px;
}

.title_section_project h1 span {
  background-image: var(--title-gradient-color);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}
.section_projec {
  padding: 125px 0px;
}
.title_section_project h1 {
  font-size: 67px;
  color: #fff;
  /* font-family: var(--main-Bold); */
  text-align: center;
}
.card_gray {
  background-color: #e5e5e678;
  border-radius: 10px;
  box-shadow: none;
  padding: 27px 25px;
}
.card_gray .imag_section_detile {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}
.card_gray .imag_section_detile .image_coin_s {
  width: 15%;
  padding: 5px;
  border: 1px solid #f6794d;
  border-radius: 10px;
  margin-right: 12px;
}
.card_gray .imag_section_detile .image_coin_s img {
  width: 100%;
  margin: 0px auto;
  display: block;
}
.Content_dive {
  width: 85%;
}
.Content_dive h3 {
  /* font-family: var(--main-Bold); */
  font-size: 24px;
}
.Content_dive p {
  color: #6f7277;
  margin-top: 8px;
  display: block;
  /* font-family: var(--main-Light); */
}
.button_action a {
  color: #fff;
  padding: 7px 15px;
  border: 0px solid #ccc;
  margin-right: 10px;
  display: block;
  background: #050505;
  width: fit-content;
  border-radius: 10px;
  /* font-family: var(--main-Medium); */
  text-decoration: none;
}
.button_action {
  display: flex;
  padding: 10px 0px;
}
.white_card_ {
  background: #fff;
  padding: 10px;
  border-radius: 10px;
  display: flex;
  flex-wrap: wrap;
}
.white_card_ .conytengt_white {
  width: 47%;
  padding: 15px 10px;
  background: #f3f1f0;
  border-radius: 10px;
  margin: 5px;
}
.white_card_ .conytengt_white span {
  /* font-family: var(--main-Bold); */
  font-size: 15px;
  color: #f4663e;
  margin-bottom: 5px;
  display: block;
}
.white_card_ .conytengt_white h4 {
  /* font-family: var(--main-Bold); */
  font-size: 16px;
  margin: 0;
}
.card_gray.coin_price_detils {
  padding: 15px 10px;
}
.text_class_fkes {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.token_title {
  display: flex;
  align-items: center;
}
.token_title span {
  width: 30px;
  height: 30px;
  /* font-family: var(--main-Bold); */
  font-size: 20px;
  display: grid;
  place-content: center;
  background: #ccc;
  background: linear-gradient(190deg, #ffda99, #f4663e 70%) !important;
  color: #fff;
  border-radius: 7px;
  margin-right: 8px;
}
.token_title p {
  margin: 0;
  /* font-family: var(--main-Bold); */
  font-size: 14px;
}

.class_yehr h4 {
  /* font-family: var(--main-Bold); */
  font-size: 18px;
  margin: 0;
}
.card_gray_heade {
  padding: 10px 0px;
  margin-top: 17px;
}
.text_sfdf {
  /* font-family: var(--main-Bold); */
  font-size: 18px;
  margin: 0;
  padding: 4px 10px;
}
.tabs {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem;
  background-color: #f2f2f2;
  width: 10rem;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
}

.tabs button {
  margin-bottom: 1rem;
  padding: 1rem;
  border: none;
  background-color: transparent;
  cursor: pointer;
}

.tabs button.active {
  color: white;
  background-color: #333;
}

.content {
  display: flex;
  flex-direction: column;
}
.stickytaba {
  /* position: relative; */
  position: sticky;
  top: 90px;
}
.active_tabs_section {
  list-style: none;
  background-color: #e5e5e678;
  border-radius: 10px;
  box-shadow: none;
  overflow: hidden;
  margin-top: 15px;
}
.active_tabs_section li {
  margin: 0;
  /* font-family: var(--main-Bold); */
  font-size: 18px;
  padding: 15px 20px;
  overflow: hidden;
  cursor: pointer;
}
.active_tabs_section li.active {
  background: #f4663e;
  color: #fff;
}
.active_tabs_section li i {
  font-size: 25px;
  margin-right: 5px;
}
.section_seleecd {
  background: rgb(243, 241, 240);
  padding: 25px 25px;
  margin: 14px 0px;
  border-radius: 10px;
}
.section_seleecd h2 {
  margin: 0;
  /* font-family: var(--main-Bold); */
  font-size: 23px;
  margin-bottom: 11px;
}
.section_seleecd p {
  color: #6f7277;
  /* font-family: var(--main-Medium); */
  margin-bottom: 15px;
}
.section_seleecd img {
  margin: 15px auto;
}
.content_side_br ul {
  padding: 0px 18px;
}
.content_side_br ul li {
  color: #6f7277;
  /* font-family: var(--main-Medium); */
  margin-bottom: 5px;
}
.content_side_br ul li::marker {
  color: #f4663e;
}
.seprate_content {
  background-color: #e5e5e678;
  padding: 15px 20px;
  border-radius: 10px;
}
.seprate_content ul {
  list-style: none;
  display: flex;
  margin-top: 10px;
  margin-bottom: 5px;
}
.seprate_content ul li a {
  width: 35px;
  display: block;
  height: 35px;
  display: grid;
  place-content: center;
  color: #000;
  background: #fff;
  border-radius: 7px;
  border: 1px solid #f3663e;
  margin-right: 5px;
  font-size: 16px;
  text-decoration: none;
}
.seprate_content h3 {
  margin: 0;
  /* font-family: var(--main-Bold); */
  font-size: 20px;
  margin-bottom: 11px;
}
.link_section1 {
  width: 100%;
  padding: 10px 13px;
  background: #fff;
  border-radius: 10px;
  border: 1px solid #f4663e;
  color: #6f7277;
  /* font-family: var(--main-Medium); */
  margin-bottom: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.link_section1 a {
  display: flex;
  font-size: 24px;
  color: #f4663e;
  text-decoration: none;
}
.colo_section {
  background: #fff;
}
.applay_form_title h2 {
  font-size: 40px;
  color: #000;
  /* font-family: var(--main-Bold); */
  text-align: center;
  margin-bottom: 40px;
}
.form_secrtion h3 {
  /* font-family: var(--main-Bold); */
  font-size: 22px;
  margin: 0;
  padding: 10px 0px;
  margin-bottom: 20px;
  border-bottom: 1px solid #cccccc5e;
  padding-bottom: 15px;
}
.form_secrtion label {
  color: #888b90;
  font-size: 16px;
  /* font-family: var(--main-Medium); */
  margin-bottom: 14px;
}
.form_secrtion .form-control {
  background: #fff;
  height: 53px;
  border: 1px solid #cccccc45;
}
.form_secrtion .marginf {
  margin-bottom: 15px;
}
.form_secrtion {
  padding: 20px;
}
.text_area_ {
  display: flex;
  flex-direction: column;
}
.text_area_ textarea {
  background: #fff;
  height: 100px;
  border: 1px solid #cccccc45;
}
.marginf .react-dropdown-select {
  background: #fff;
  height: 53px;
  border: 1px solid #cccccc45;
}
button.form_submit {
  text-transform: capitalize;
  color: #fff;
  background: #ccc;
  padding: 13px 35px;
  margin: 0 auto;
  display: block;
  margin-top: 27px;
  border-radius: 5px !important;
  background: linear-gradient(190deg, #ffda99, #f4663e 70%) !important;
  color: #fff !important;
}
.login_form {
  min-height: 100vh;
  background: #f4f4f4;
  padding: 150px 0px;
}
.card_login {
  padding: 3em 3rem;
  border-radius: 1rem;
  flex-direction: column;
  background-color: #fff;
}
.card_login h3 {
  color: #000;
  font-size: 30px;
  /* font-family: var(--main-Bold); */
  margin-bottom: 17px;
}
.card_login .form-control {
  height: 72px;
  border-radius: 12px;
  margin-bottom: 16px;
}
.form_login_input button,
.form_login_input button:hover {
  padding: 19px 44px;
  font-size: 22px;
  background: rgba(5, 5, 5);
  /* font-family: Kanit; */
  font-weight: 600;
  line-height: 26.4px;
  border-radius: 12px;
  text-transform: capitalize;
  color: #fff;
  width: 100%;
}

.Butn_header {
  /* font-family: var(--main-Medium); */
  text-transform: capitalize !important;
  padding: 6px 20px !important;
  box-shadow: none !important;
  border-radius: 6px !important;
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%);
  color: #fff !important;
  margin-right: 0;
  text-decoration: none;
  font-size: 14px;
}
.dark .card_inside_content .blog-image img {
  filter: none !important;
}
.Butn_header:hover {
  /* font-family: var(--main-Medium); */
  text-transform: capitalize !important;
  padding: 6px 20px !important;
  box-shadow: none !important;
  border-radius: 6px !important;
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%);
  color: #fff !important;
  margin-right: 0px;
  text-decoration: none;
  font-size: 14px;
}
.dark_change {
  /* padding: 9px 11px !important; */
  height: 40px;
  width: 28px;
  display: grid;
  place-content: center;
  font-size: 20px;
  border-radius: 50% !important;
  transform: scale(0.9);
}
.dark_change:hover {
  border-radius: 50% !important;
}

.link_top_section {
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%);
  padding: 10px 0px;
}
.link_top_section p {
  font-size: 14px !important;
  /* font-family: "ProductSans_Light"; */
}
.link_top_section a,
.link_top_section span {
  color: #fff;
  font-size: 16px;
  padding: 0px 8px;
  font-weight: 400;
  /* font-family: "ProductSans_Regular"; */
}
.img_section img {
  width: 60%;
  margin: 0 auto;
  display: block;
  /* margin-top: -40px; */
  position: relative;
  z-index: 1111;
}
.content_text a {
  text-decoration: none;
  color: #616161;
  /* font-family: "ProductSans_Regular"; */
  margin-bottom: 0;
}
.hero_seaction_law {
  min-height: 100vh;
  /* padding-top: 100px; */
  /* background-image: url(./Image/banner/banner_circle.png); */
  background-position: center center;
  background-size: contain;
  background-repeat: no-repeat;
  position: relative;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.section_banner_hero {
  position: relative;
  padding-top: 44px;
  overflow: hidden;
  min-height: 100vh;
}
.section_banner_hero .purble {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 30%;
  height: 30%;
  background: rgb(179 133 231 / 98%);
  /* box-shadow: 187.93650817871094px 187.93650817871094px 187.93650817871094px; */
  filter: blur(117.94px);
  animation: move1 5s infinite ease-in-out;
}
.section_banner_hero .green {
  position: absolute;
  top: 10%;
  left: 85%;
  transform: translate(-50%, -10%);
  width: 20%;
  height: 40%;
  background: #a1efd8;
  /* box-shadow: 187.93650817871094px 187.93650817871094px 187.93650817871094px; */
  filter: blur(97.94px);
  animation: move2 5s infinite ease-in-out;
}
.section_banner_hero .yellow {
  position: absolute;
  top: 0%;
  left: 10%;
  transform: translate(-50%, -10%);
  width: 20%;
  height: 40%;
  background: #f4e398;
  /* box-shadow: 187.93650817871094px 187.93650817871094px 187.93650817871094px; */
  filter: blur(97.94px);
  animation: move3 5s infinite ease-in-out;
}

.hero_seaction_law h1 {
  font-size: 70px;
  color: #1a1a1a;
  margin: 0;
}
.hero_seaction_law h2 {
  font-size: 32px;
  color: #1a1a1a;

  /* font-family: "ProductSans_Light"; */
}
.hero_seaction_law p {
  font-size: 18px;
  color: #1a1a1a;
  text-align: center;
  margin: 20px;
  font-weight: 400;
}

.dark .hero_seaction_law h1 {
  font-size: 70px;
  color: #f7f7f7;
  margin: 0;
  filter: drop-shadow(0px 2px 3px #3b393952);
}
.dark .hero_seaction_law h2 {
  font-size: 32px;
  color: #f7f7f7;
  filter: drop-shadow(0px 2px 3px #3b393952);
  /* font-family: "ProductSans_Light"; */
}
.dark .hero_seaction_law p {
  font-size: 18;
  color: #f7f7f7;
  text-align: center;
  filter: drop-shadow(0px 2px 3px #3b393952);
  margin: 20px;
  /* font-family: "ProductSan_Medium"; */
}
button.laight_button {
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%);
  color: #fff;
  padding: 10px 20px;
  /* font-family: "ProductSan_Medium" !important; */
  font-size: 15px;
  text-transform: capitalize;
  border-radius: 30px;
  margin-top: 10px;
}
.button.laight_button:hover {
  background: #1f1f1f !important;
}
.hero_seaction_law img {
  width: 57%;
  margin-top: 60px;
}

.padding_adjust {
  padding: 0px 30px;
}
.section_defualt {
  padding: 70px 0px;
}
.imag_widtjas {
  width: 80%;
  margin: 0 auto;
  display: block;
}
.overvide {
  /* padding: 10px 20px; */
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
}
.overvide h2 {
  font-size: 40px;
  margin-bottom: 16px;
  /* font-family: "ProductSans_Regular"; */
}
.overvide p {
  font-size: 18px;
  margin-bottom: 16px;
  /* font-family: "ProductSans_Regular"; */
  text-align: justify;
}
.MuiList-root {
  min-height: 91px !important;
}
.bg-gray_section {
  background: #f3f5fc;
}
section.bg-gray_section-hero {
  background-color: #f3f5fc !important;
}
.bg_imge_non::after {
  background-image: none !important;
}
.bg_imge_non {
  border-radius: 0 !important;
}
.w-80 {
  max-width: 80% !important;
}
section.new_Section_AIdoc.heaight_excahnge {
  height: 550px !important;
  min-height: 550px !important;
  border-radius: 0px !important;
  background-image: none !important;
}
.fixed_hero_height {
  padding: 0 !important;
  height: auto !important;
  min-height: auto !important;
}
.section_fixed_height_sedcion {
  display: flex;
  align-items: center !important;
  padding-top: 123px !important;
  padding-bottom: 30px !important;
}
/* .img_center_section_wallet {
  width: 70%;
  display: flex;
  justify-content: end;
  margin: 0 auto;
} */
.logo_barnd {
  justify-content: center !important;
}
.img_contaoiner {
  background: #f3f5fc;
  padding: 14px 0px;
  border-radius: 10px;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.footer_card_sec a {
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%);
  color: #fff;
  padding: 10px 20px;
  /* font-family: "ProductSan_Medium" !important; */
  font-size: 15px;
  text-transform: capitalize;
  border-radius: 30px;
  margin-top: 10px;
  text-decoration: none;
}
.logo_barnd .keyfetupload-item p {
  color: #6b6b6b;
  font-size: 1em;
  line-height: 1.6em;
  margin: 0;
  text-align: justify;
}
.footer_card_sec {
  display: flex;
  justify-content: flex-end;
}
.img_contaoiner img {
  width: 60%;
  margin: 10px auto;
  display: block;
}
.logo_barnd .keyfetupload-item {
  flex-basis: calc(33% - 0px);
  max-width: calc(33% - 10px);
}
.logo_barnd.width_50 .keyfetupload-item {
  flex-basis: calc(48% - 0px);
  max-width: calc(48% - 10px);
}
.heaight_excahnge::after {
  display: none !important;
}
.bg-gray_section2 {
  background: #f9f9fa;
  margin-top: 45px;
}
.image_contain_digital {
  height: 100%;
  margin: 0;
  width: 100%;
}
.sign_processs {
}
.item_white .keyfetupload-item {
  background: #fff;
}

.why_digilat ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

.why_digilat li {
  margin-bottom: 2px;
  font-size: 16px;
  line-height: 1.5;
  /* display: flex; */
  align-items: center;
}

.why_digilat li::before {
  content: "●";
  color: #6541e9; /* Purple color */
  font-size: 22px;
  margin-right: 10px;
}

.why_digilat li b {
  margin-right: 5px;
  font-weight: 500;
}
.keyfetupload-item {
  background-color: #f3f5fc;
  border-radius: 8px;
  padding: 20px;
  box-shadow: none;
  flex-grow: 1;
  flex-shrink: 1;
  flex-basis: calc(50% - 0px);
  max-width: calc(50% - 10px);
  text-align: left;
}
.card_inside_content h3 {
  font-size: 25px;
  margin-bottom: 8px;
  /* font-family: "ProductSans_Regular"; */
  text-transform: capitalize;
}
.card_inside_content p {
  font-size: 17px;
  margin-bottom: 0px;
  /* font-family: "ProductSans_Regular"; */
  color: #1f1f1f;
  /* letter-spacing: 0.5px; */
  line-height: 23px;
  text-align: justify;
}
button.laight_button.card_butotn {
  font-size: 13px;
  padding: 7px 14px;
  margin-bottom: 15px;
  margin-top: 0;
  /* font-family: "ProductSans_Regular" !important; */
}
.card_inside_content .imag_caed {
  border: 0.5px #3a5aff solid;
  width: fit-content;
  width: 60px;
  height: 60px;
  display: flex;
  /* align-items: center; */
  /* justify-content: center; */
  padding: 9px;
  border-radius: 60%;
  margin-bottom: 20px;
}
.lable_badge {
  padding: 7px 15px;
  border: 1px solid #dadce0;
  background: #fff;
  border-radius: 30px;
  margin-bottom: 12px;
  color: #5f6368;
}
.text-captailz {
  text-transform: capitalize;
}
.blog-image {
  width: 336px;
  height: 264px;
  display: flex;
  margin-bottom: 16px;
  border-radius: 10px;
  overflow: hidden;
}
.tab-pane .blog-image {
  height: 100%;
}
.blog_data {
  display: flex;
  gap: 10px;
  margin-bottom: 16px;
  color: #616161;
}
.blog_data span i {
  margin-right: 5px;
}
.light_cwnte {
  line-height: 25px;
}
.testimonial-slider-container {
  width: 100%;
  overflow: hidden;
  position: relative;
  padding-bottom: 50px; /* Add padding to accommodate the arrows */
  min-height: 300px;
  /* Adjust this value to fit the height of your slides */
}

.testimonial-slide {
  display: flex !important;
  justify-content: center;
}

.slick-slide {
  display: flex !important;
  justify-content: center;
}
.slick-list {
  height: 260px !important;
}

.slick-list {
  overflow: hidden !important;
}

.slick-track {
  display: flex !important;
}

.arrow {
  position: absolute;
  bottom: -40px;
  width: 40px;
  height: 40px;
  background-color: #1a1a1a;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 1;
  font-size: 23px;
  color: #fff;
}

.arrow.prev {
  left: 45%;
}

.arrow.next {
  right: 45%;
}
.pricong h3 {
  font-size: 30px;
  margin-bottom: 0px;
  /* font-family: "ProductSans_Regular"; */
  color: #1a1a1a;
}
.pricong p {
  font-size: 18px;
  margin-bottom: 8px;
  /* font-family: "ProductSans_Regular"; */
  color: #1a1a1a;
}
.grdionet {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0) 0%,
    rgba(0, 0, 0, 1) 49%,
    rgba(0, 212, 255, 0) 100%
  );
}
.pricong ul {
  list-style: none;
  margin: 0;
}
.pricong ul li {
  display: flex;
  align-items: self-start;
  gap: 5px;
  font-size: 16px;
  color: #1f1f1f;
  /* font-family: "ProductSans_Regular"; */
  margin-bottom: 10px;
}
button.padun {
  margin-bottom: 7px !important;
  padding: 10px 0px !important;
}
.pricong ul li i {
  font-size: 20px;
  color: #773beb;
}
.card_bg_ {
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%);
}
.dark .ri-medium-fill {
  color: #fff;
}
button.button_white {
  background: #fff !important;
  color: #000 !important;
}
.grdionet_white {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0) 0%,
    rgb(255 255 255) 49%,
    rgba(0, 212, 255, 0) 100%
  );
}
.select_s .MuiFormLabel-root {
  background: #f9f9fa;
}
.list_section {
  display: flex;
  align-items: center;
  gap: 20px;
  margin-bottom: 30px;
}
.list_section .circle_boder {
  width: 50px;
  height: 50px;
  display: grid;
  place-content: center;
  border: 1px solid #6c3eea;
  border-radius: 50%;
}
.text_faq {
  font-size: 17px !important;
  color: #000 !important;
  font-weight: 500 Im !important;
  /* font-family: "ProductSans_Regular" !important; */
}
.content_text h4 {
  margin: 0;
  font-size: 18px;
  margin-bottom: 5px;
  /* font-family: "ProductSans_Regular"; */
}
.content_text p {
  color: #616161;
  /* font-family: "ProductSans_Regular"; */
  margin-bottom: 0;
}
.text_trie {
  font-size: 30px !important;
  font-weight: 600 !important;
  margin-bottom: 40px !important;
}
.marbu {
  margin-bottom: 10px !important;
}
.bg_selcton_faq {
  background-image: url("./Image//banner/glob-image.webp");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
@keyframes move1 {
  0% {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(100px, 50px);
  }
  66% {
    transform: translate(-150px, 50px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes move2 {
  0% {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(-150px, 100px);
  }
  66% {
    transform: translate(200px, -150px);
  }
  100% {
    transform: translate(0, 0);
  }
}

@keyframes move3 {
  0% {
    transform: translate(0, 0);
  }
  33% {
    transform: translate(200px, -100px);
  }
  66% {
    transform: translate(-150px, 200px);
  }
  100% {
    transform: translate(0, 0);
  }
}

.hovwer_card:hover {
  cursor: pointer;
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%) !important;
}
.hovwer_card:hover .imag_caed {
  border-color: #fff !important;
}
.hovwer_card:hover .imag_caed img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
    contrast(103%);
}
.hovwer_card:hover .grdionet {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0) 0%,
    rgb(255 255 255) 49%,
    rgba(0, 212, 255, 0) 100%
  );
}
.hovwer_card:hover .pricong p {
  color: #fff;
}
.hovwer_card:hover .pricong h3 {
  color: #fff;
}
.hovwer_card:hover .pricong ul li i {
  color: #fff;
}
.hovwer_card:hover .pricong ul li {
  color: #fff;
}
.hovwer_card:hover .text_colos {
  color: #fff;
}
.hovwer_card:hover button.laight_button {
  background: #fff !important;
  color: #000 !important;
}
.dropdown:hover .dropdown-menu {
  display: block;
}
.itempadding a {
  padding: 3px 15px;
}
.itempadding .dropdown-menu {
  border: none;
  box-shadow: 0px 0px 5px #ccc;
  margin-top: -4px;
}
.blog_banner {
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%);
}
.blog_home_banner h1 {
  font-size: 65px !important;
  line-height: 74px;
  color: #fff;
}
.blog_home_banner p {
  margin: 0;
  font-size: 25px;
  text-align: left;
  margin-top: 20px;
  line-height: 35px;
  color: #fff;
  /* font-family: "ProductSans_Regular"; */
}
.blog_home_banner .laight_button {
  background: #fff;
  color: #000;
}
.image_blog {
  width: 70%;
  margin: 0 auto;
  display: block;
}
.baner_blog_top {
  margin-top: -230px;
}
.baner_blog_top .slick-list {
  height: auto !important;
  padding-bottom: 40px;
}
.baner_blog_top .slick-dots li button:before {
  font-size: 14px;
  opacity: 0.25;
  color: #783bec;
}
.baner_blog_top .slick-dots li.slick-active button:before {
  opacity: 0.75;
  color: #783bec;
}
.flex-direction-row {
  flex-direction: row !important;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: #f8f9fa;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.card {
  margin-top: 20px;
}
.blog_tabs ul {
  background: #fff;
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 30px;
  overflow: hidden;
}
.blog_tabs .nav-tabs .nav-link.active {
  border: none;
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%);
  border-radius: 30px;
  padding: 12px 15px;
  color: #fff;
}
.blog_tabs .nav-tabs .nav-link {
  border: none;
  padding: 12px 15px;
  color: #000;
  transition-duration: all 0.3s;
}
.blog_tabs .nav-tabs .nav-link:hover {
  border: none;
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%);
  border-radius: 30px;
  padding: 12px 15px;
  color: #fff;
}
.card-custom {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
  background-color: #fff;
  color: rgba(0, 0, 0, 0.87);
  -webkit-transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  border-radius: 4px;
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2),
    0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12);
  overflow: hidden;
  border: none;
}
.card-custom .card-content {
  padding: 0;
}
.card-custom .card-content .card-inside-content {
  display: flex;
  flex-direction: row;
}
.card-custom .card-content .blog-image img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin-bottom: 0;
}
.card-custom .blog-image {
  margin-bottom: 0;
}
.card-custom .card-content h3 {
  font-size: 25px;
  margin-bottom: 8px;
  /* font-family: "ProductSans_Regular"; */
  text-transform: capitalize;
}
.card-custom .card-content p {
  margin-bottom: 0;
  font-size: 18px;
  color: #616161;
}
.card-custom .card-content .blog_data {
  /* margin-top: 1rem; */
  display: flex;
  flex-direction: row;
  gap: 1rem;
  /* font-family: "ProductSans_Regular"; */

  font-size: 0.9rem;
  color: #616161;
  font-weight: 500;
}
.input_fore {
  display: flex;
  justify-content: center;
  align-items: center;
}
.input_fore input {
  height: 50px;
  padding: 20px 17px;
  border: 1px solid #ccc;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;
}
.articelee {
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.mt-90 {
  padding-top: 120px;
}
.text_card {
  text-align: left;
  margin-bottom: 14px;
  color: #161616;
  margin-bottom: 0;
}
.input_fore button {
  border: none;
  height: 50px;
  padding: 0px 20px;
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%);
  color: #fff;
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}
.nav-item {
  flex-grow: 1;
}
.tab-content {
  padding-top: 30px;
}
.card-custom .card-actions {
  padding: 0;
  margin-top: 1rem;
}
.card-custom .card-actions .btn {
  display: flex;
  align-items: center;
}
.blg_imag {
  height: 475px;
  display: flex;
  justify-content: end;
}
.blg_imag img {
  object-fit: contain;
  border-radius: 15px;
}
.blg_contant_section h2 {
  margin-bottom: 20px;
}
.blg_contant_section h3 {
  font-size: 25px;
}
.blg_contant_section ol,
.blg_contant_section ul ol {
  padding-left: 0;
  list-style: none;
}
.blg_contant_section ol li,
.blg_contant_section ul li {
  margin-bottom: 15px;
}
.sectin_cate {
  display: flex;
  align-items: center;
  gap: 20px;
}
.sectin_cate span {
  color: #616161;
  font-size: 18px;
}
.sectin_cate span i {
  margin-right: 5px;
}
.blg_contant_section p {
  margin: 15px 0px;
  font-size: 18px;
  text-align: justify;
}
.quate {
  padding: 0px 15px;
  border-left: 6px solid #8462ff;
}
.quate h6 {
  font-size: 30px;
  line-height: 39px;
}
.social_icon .social a {
  color: #1f1f1f;
  font-size: 24px;
  padding: 0px 0px;
}
.social_icon .social {
  gap: 15px;
}
.social_icon .social a {
  height: 50px;
  width: 50px;
  display: grid;
  place-content: center;
  border: 1px solid #ccc;
  border-radius: 50%;
}
.social_icon .social a:hover {
  color: #fff;
  text-decoration: none !important;
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%);
}
.link_tabe ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.link_tabe li a {
  display: flex;
  width: 100%;
  padding: 15px 20px;
  color: #161616;
  background: transparent;
  border-radius: 8px;
}
.link_tabe li a:hover {
  display: flex;
  width: 100%;
  padding: 15px 20px;
  color: #fff;
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%);
  border-radius: 8px;
  text-decoration: none;
}
.bloh_carde h4 {
  font-size: 17px;
}
.cursoe {
  cursor: pointer;
}
.bloh_carde .sectin_cate span {
  font-size: 15px;
}
.bg-white {
  background-color: #fff !important;
}
.didgtal_hero {
  background-image: url("./Image/banner/digital-hero.webp");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: right;
}
.bg-image-new {
  background-image: url("./Image/banner/alternativeResolution.webp") !important;
  background-size: 40%;
}
.new-iamge_alter {
  background-image: url("./Image/banner/dispute-relution.webp") !important;
  background-size: 40%;
}
.section_banner_hero.new_Section_AIdoc.uploaddocs.bg-image-new {
  height: 100% !important;
  min-height: 80vh !important;
}

.color_white header {
  background: #fff !important;
  border-bottom: 0.5px solid #1212124f !important;
}

.banner_vierutal_hero {
}
.margin_bottons_virtyua {
  margin-bottom: -210px !important;
}
.benifitlaw-list {
  list-style: none;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  /* max-width: 600px; */
}

.benifitlaw-list li {
  display: flex;
  align-items: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 15px 20px;
  /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.benifitlaw-list li .circle {
  background-color: #6f41e8;
  color: white;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 15px;
  /* font-weight: bold; */
}

.benifitlaw-list li .text {
  font-size: 16px;
  color: #333;
  font-weight: 600;
}
.bg_image_mediaor {
  background-image: url("./Image/banner/mediatoer_hero.webp");
  background-size: 40%;
  background-position: bottom right;
}
.flex_align_werp {
  justify-content: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.bottin_imag_law {
  width: 70%;
  margin: 0 auto;
  display: block;
  margin-bottom: -70px !important;
}
.color_gray_card li {
  background-color: #f3f5fc !important;
}

.law_aichat .container1 {
  display: flex;
  gap: 10px;
  padding: 20px;
  /* max-width: 1200px; */
  margin: 0 auto;
  flex-wrap: wrap;
  justify-content: center;
}

.container1 .item {
  background-color: #fff;
  /* border: 1px solid #ddd; */
  width: 32%;
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
  display: flex;
  justify-content: center;
  align-items: start;
  gap: 20px;
}

.container1 .item h2 {
  font-size: 2em;
  color: #5245e5;
  margin: 0;
}

.container1 .item p {
  font-size: 1em;
  color: #333;
  margin-top: 10px;
  margin-top: 0;
  text-align: left;
  margin-bottom: 0;
}
.show_light {
  display: block;
}
.show_dark {
  display: none;
}
.steps_to_create li {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.tranc_xlass li {
  background: transparent;
}
.card_layput li {
  background: transparent;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 11px;
  background: #fff;
}
.h3_color {
  margin: 0 !important;
  font-size: 30px !important;
  color: #5e42e8 !important;
}
.colors_white .feature-box {
  background: #fff !important;
  margin-left: 0 !important;
  margin-right: 0 !important;
}
.gap_3 {
  gap: 20px !important;
}
.blg_contant_section ul {
  list-style: none !important;
}
/* darkmode */
body.dark {
  background-color: #161616 !important;
}
.dark .show_light {
  display: none;
}
.dark .filter_non {
  filter: none !important;
}
.dark .show_dark {
  display: block;
}
.dark .item_white .keyfetupload-item {
  background: #161616;
}
.dark .img_contaoiner {
  background: #121212;
}
.dark .img_contaoiner img {
  filter: invert(96%) sepia(0%) saturate(7481%) hue-rotate(338deg)
    brightness(107%) contrast(100%);
}
.marketplace_bg {
  background-image: url("./Image/banner/bg-market.webp") !important;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: right !important;
}
.dark section.marketplace_bg {
  background-image: url("./Image/banner/dark_marketplace.webp") !important;
}
.dark .didgtal_hero {
  background-image: url("./Image/banner/dark_bg.webp") !important;
}
.dark .bg_image_mediaor {
  background-image: url("./Image/banner/mediatoer_hero.webp") !important;
  background-size: 40%;
  background-position: bottom right;
}
.dark .benifitlaw-list li {
  background-color: #161616;
}
.dark .benifitlaw-list li .text {
  color: #fff;
}
.dark .why_digilat li {
  color: #fff;
}
.dark .color_gray_card li {
  background-color: #121212 !important;
}
.itempadding {
  height: 40px;
  display: grid;
  place-content: center;
}

.dark .itempadding .dropdown-menu {
  border: none;
  box-shadow: 0px 0px 5px #000;
  margin-top: -5px;
  background: #050505;
}
.dark .itempadding .dropdown-menu a:hover {
  color: #fff;
  background: #783bed;
}
.dark .nav_link_section a {
  color: #f7f7f7 !important;
  /* font-family: var(--main-Medium); */
}
.dark .hero_seaction_law {
  /* padding-top: 100px; */
  background-image: none;
}
.dark .hero_seaction_law h1,
.dark .hero_seaction_law h2 {
  color: #f7f7f7;
}
.dark .hero_seaction_law p {
  /* color: #868686; */
  color: #f7f7f7;
}

.dark .header.scrolled > header {
  background-color: #161616 !important;
  border-bottom: 1px solid #332e2e !important;
}

.dark .header.scrolled .whiteshow {
  display: none;
}
.dark .header.scrolled .blackshow {
  display: block;
}
.dark .nav_link_section a {
  color: #f7f7f7 !important;
  /* font-family: var(--main-Medium); */
}
.dark .Butn_header {
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%);
}

.dark .overvide h2,
.dark .overvide p {
  color: #f7f7f7 !important;
}

.dark .bg-gray_section {
  background: #121212;
}

.dark .bg-gray_section2 {
  background: #121212;
  margin-top: 45px;
}

.dark .card_dark {
  background: #161616 !important;
  border: 1px solid #292929 !important;
}
.dark .card_dark .pricong img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
    contrast(103%) !important;
}
.dark .card_inside_content h3,
.dark .card_inside_content p {
  color: #f7f7f7 !important;
}

.dark .pricong ul li {
  color: #f7f7f7 !important;
}
.dark .grdionet {
  background: linear-gradient(
    90deg,
    rgba(2, 0, 36, 0) 0%,
    rgb(255 255 255) 49%,
    rgba(0, 212, 255, 0) 100%
  );
}
.dark .text_colos {
  color: #868686;
}
.dark .card_dark.darkPrice {
  background: #121212 !important;
}
.dark .card_dark.darkPrice.hovwer_card:hover {
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%) !important;
}
.dark .blog_data {
  color: #868686;
}

.dark .arrow {
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%);
}
.dark .slider_test h6 {
  color: #f7f7f7;
}
.dark .slider_test p {
  color: #868686;
}
.dark .text_trie,
.dark .content_text h4 {
  color: #f7f7f7 !important;
}
.dark .circle_boder svg {
  fill: #fff;
}
.dark .content_text p {
  color: #868686;
}
.dark .link_contact svg {
  fill: #fff;
}
.dark_name {
  background: #000;
}
.dark .select_s .MuiFormLabel-root {
  background: #121212 !important;
}
.dark .input_typr_text {
  background: #121212 !important;
  color: #fff !important;
  border: 1px solid #292929 !important;
}
.dark .MuiSelect-select {
  color: #fff;
}
.dark .input_typr_text textarea {
  color: #fff;
}
.dark .bg_selcton_faq {
  background-image: url("./Image//banner/glob-image-dark.webp");
}
.dark .input_typr_text input {
  color: #fff !important;
}
.dark .input_typr_text label {
  color: #868686 !important;
}
.dark .Faq_card p {
  color: #fff !important;
}
.dark .MuiAccordionSummary-content {
  color: #fff !important;
}
.dark .MuiAccordionSummary-expandIconWrapper svg {
  fill: #fff !important;
}
.dark footer {
  background: #121212;
  border-top: 1px solid #292929 !important;
}

.dark .logo_section_f p {
  color: #868686 !important;
}

.dark .link_section h4 {
  color: #f7f7f7;
}

.dark .link_section ul a {
  color: #868686 !important;
}
.dark .social a {
  color: #ffffff;
}

.dark .card_inside_content img {
  filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(93deg) brightness(103%)
    contrast(103%) !important;
}

.dark .blog_banner {
  background: url("./Image/banner/banner_dark_blog.webp");
  background-size: cover;
}

.dark .blog_home_banner p {
  text-align: left;
  margin-left: 0px;
}
.dark .image_blog {
  display: none;
}
.dark .blog_tabs ul {
  background: #161616 !important;
  border: 1px solid #292929 !important;
}
.dark .blog_tabs .nav-tabs .nav-link {
  color: #fff;
}
.dark .blog_body {
  background: #03010c !important;
}
.dark .card-custom {
  background: #161616 !important;
  border: 1px solid #292929 !important;
}
.dark .blg_contant_section {
  color: #fff;
}
.dark .blg_contant_section p {
  color: #909090;
}
.dark .card-custom .card-content h3 {
  color: #fff;
}
.dark .link_tabe li a {
  color: #fff;
}
.dark .input_fore input {
  background: #161616 !important;
  border: 1px solid #292929 !important;
  color: #fff;
}
.dark .bloh_carde h4 {
  font-size: 17px;
  color: #fff;
}
.dark .bg-image-new {
  background-image: url("./Image/banner/alternativeResolution.webp") !important;
  background-size: 40%;
}
.dark .new-iamge_alter {
  background-image: url("./Image/banner/dispute-relution.webp") !important;
  background-size: 40%;
}

.dark .section_banner_hero.new_Section_AIdoc.uploaddocs.bg-image-new {
  height: 80vh !important;
  min-height: 80vh !important;
}
.dark .option_barnd .img_contaoiner img {
  filter: none !important;
}
/* Add these styles in your CSS file */

.img_section {
  cursor: pointer;
  position: relative;
  z-index: 1;
}

.video-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  border-radius: 10px;
}

.button_moduke > button {
  background: linear-gradient(144deg, #5146e5 0%, #7b3aed 89%);
  margin-right: -20px;
  color: #fff;
  margin-top: -20px;
}
.video-background {
  position: relative;
  width: 100%;
  height: 100vh;
  overflow: hidden;
}

.video-background video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: none;
}
.video-background {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-image: url("./Image//banner//light_bg_gif.gif");
}
.dark .video-background video {
  display: block;
}
.section-content {
  position: relative;
  z-index: 1;
  color: white;
  text-align: center;
  padding: 20px;
  /* background: #00000040; */
}
.header_drawer_icon {
  margin: 0 auto;
  width: 150px;
  margin-bottom: 20px;
  margin-top: 10px;
}
.bg_drawe svg {
  color: #000;
}
.dark .bg_drawe svg {
  color: #fff;
}
.header.scrolled .bg_drawe svg {
  color: #000;
}
.dark .header.scrolled .bg_drawe svg {
  color: #fff;
}
.button_moduke {
  width: fit-content !important ;
}
.imag_widtjas img {
  border-radius: 10px;
}
.new_icon {
  border: none !important;
  width: 80px !important;
  height: 80px !important;
}
.dark .new_icon img {
  filter: none !important;
}
.dark .blg_contant_section li {
  color: #909090;
}
.dark .blg_contant_section li strong {
  color: #fff;
}
.dark .social_icon h4 {
  color: #fff;
}
.card_link {
  text-decoration: none !important;
}
.section_marke {
  padding-top: 40px;
}
.slider_card .card_dark {
  min-height: 450px;
}
.MuiDrawer-root {
  z-index: 5555555;
}
.max-w-100 {
  max-width: 100% !important;
}
.logo_slide .slick-list {
  height: 160px !important;
}

section.new_Section_AIdoc.heaight_excahnge.height_contact {
  height: 300px !important;
  min-height: 300px !important;
}
section.new_Section_AIdoc.heaight_excahnge.height_contact.new_hig {
  height: 360px !important;
  min-height: 360px !important;
}
section.new_Section_AIdoc.heaight_excahnge .center_contect {
  align-items: center !important;
  justify-content: center !important;
}
.dark .colors_white .feature-box {
  background: #161616 !important;
}
.section_banner_hero.new_Section_AIdoc.wallet-h {
  height: 100% !important;
}
.section_banner_hero.new_Section_AIdoc.bg-gray_section-hero.section_fixed_height_sedcion.banner_vierutal_hero.bg_imge_non.tab_height.ai-chat-h {
  height: 100% !important;
}
.text-decoratoin a {
  text-decoration: none !important;
}
.dark .dark_icon {
  fill: #c0c0c0;
  color: #c0c0c0;
}
.dark .color_text {
  color: #fff;
}
.dark .dark_icon:hover {
  fill: #5246e5;
  color: #5246e5;
}
.dark .container1 .item {
  background-color: #161616;
}
.dark .container1 .item p {
  color: #fff;
}
.home_33_content_w {
  position: relative;
}
.chart_widyth {
  /* width: 62%; */
  margin: 0 auto;
}
.chart_widyth canvas {
  width: 100% !important;
  height: 100% !important;
}
section.new_Section_AIdoc.heaight_excahnge.height_contact_2 {
  height: 400px !important;
  min-height: 300px !important;
}

.container1 .item p b {
  font-weight: 500;
}

.padding_dsection {
  padding-top: 160px;
  z-index: 2;
  position: relative;
}

.content_h2_uyb > h1 {
  font-size: 50px;
}
.hero_seaction_law.ai_law_hero .ai_work_heading {
  font-size: 62px;
}
.img-padding-top {
  padding-top: 80px;
}
.section_banner_hero.new_Section_AIdoc.height_new.law_leg_h {
  height: 100% !important;
  min-height: 0 !important;
}
@media (max-width: 1200px) {
  .text-width-2 {
    width: 80% !important;
  }
  .none-br br {
    display: none;
  }
  .empty-data {
    display: none !important;
  }
  .hero_seaction_law.ai_law_hero .ai_work_heading {
    font-size: 54px;
  }
  section.new_Section_AIdoc.heaight_excahnge {
    height: 100% !important;
    min-height: 100% !important;
  }
  .adr-top-padding {
    margin-top: 54px !important;
  }
  .adr-top-padding h1 {
    font-size: 60px;
  }
  .hero_seaction_law h1 {
    font-size: 52px;
  }
  .text-width-80 {
    width: 90%;
  }
  .postion_imag_rel {
    position: absolute;
    right: -260px;
    width: 40%;
    top: -26px;
  }
}
@media (max-width: 1199px) {
  .text-law-w {
    width: 50%;
  }
}
@media (max-width: 1100px) {
  .marketplace_bg {
    background-image: none !important;
  }
}
@media (max-width: 1080px) {
  .hero_seaction_law h1 {
    font-size: 58px;
  }
  .hero_seaction_law.align-items-start.alter-top-paddings {
    padding-top: 100px !important;
  }
  .hero_seaction_law.align-items-start.alter-top-paddings h1 {
    font-size: 48px;
    line-height: 54px !important;
  }
  .hero_seaction_law.ai_law_hero .ai_work_heading {
    font-size: 48px;
  }
}
@media (max-width: 1024px) {
  .text-width-80 {
    width: 100%;
  }
  .postion_imag_rel {
    right: -350px;
    top: 10px;
  }
  .hero_seaction_law.ai_law_hero .ai_work_heading {
    font-size: 46px;
  }
  .width_70 {
    width: 62%;
  }
  .hero_seaction_law.align-items-start.mark_law_title h1 {
    font-size: 44px !important;
    line-height: 50px !important;
  }
  .hero_seaction_law.align-items-start.mark_law_title p {
    font-size: 16px !important;
  }
  .smart-font-lg h1 {
    font-size: 44px !important;
  }
  .section_banner_hero.new_Section_AIdoc.about_done.about-us-h {
    height: 100% !important;
    min-height: 100% !important;
  }
}
@media (max-width: 980px) {
  .hero_seaction_law p {
    font-size: 16px;
  }
}
@media (max-width: 900px) {
  .section_banner_hero.new_Section_AIdoc.height_new,
  .hero_seaction_law.align-items-start.mark_law_title {
    min-height: 460px !important;
    height: 100% !important;
  }
  .hero_seaction_law.align-items-start.ai_law_hero,
  .section_banner_hero.new_Section_AIdoc.uploaddocs.bg-white.didgtal_hero.marketplace_bg.bg-gray_section.bg-gray_section-hero.marketplace-ban {
    min-height: 460px !important;
    height: 100% !important;
  }
  .text-width-2 {
    width: 100% !important;
  }
  .section_banner_hero.new_Section_AIdoc.bg-gray_section-hero.heaight_excahnge.section_fixed_height_sedcion.banner_vierutal_hero.adr-top-padding.adr-margins {
    margin-top: 0 !important;
  }
  .text-law-w {
    width: 100%;
  }
  .smart-font-lg h1 {
    font-size: 38px !important;
  }
  .postion_imag_rel {
    display: none;
  }
  .hero_seaction_law p br {
    display: none;
  }
  .width_70 {
    width: 100%;
  }
  .blg_imag {
    height: 100%;
  }
  .blg_imag img {
    width: 100%;
  }
  .paddig_top_secion {
    padding-top: 120px !important;
  }
  .section_banner_hero.new_Section_AIdoc.tab_height {
    height: auto !important;
    padding-top: 160px !important;
  }
  .container1 .item {
    width: 49%;
  }

  .bg-image-new {
    background-image: none !important;
    /* background-size: 40%; */
  }
  section.new_Section_AIdoc.heaight_excahnge {
    height: auto !important;

    padding-top: 170px !important;
  }
  .section_banner_hero.new_Section_AIdoc.wallet_exchnage {
    height: auto !important;

    padding-top: 170px !important;
  }
  .new_Section_AIdoc::after {
    display: none;
  }
  .dark .didgtal_hero,
  .didgtal_hero {
    background-image: none !important;
  }
  /* .driwer_ss .text-white {
    color: #0e0909 !important;
  } */
  .hero_seaction_law h1 {
    font-size: 60px;
  }
  .new_Section_AIdoc .hero_seaction_law {
    margin-top: 0px !important;
  }
  .hero_seaction_law.align-items-start.alter-top-paddings {
    padding-top: 170px !important;
  }
}
@media (max-width: 768px) {
  .w-80 {
    max-width: 100% !important;
  }

  .section_banner_hero.new_Section_AIdoc.tab_height {
    height: auto !important;
    padding-top: 160px !important;
  }
  .dark .marketplace_bg {
    background-image: none !important;
  }
  .margin_zeeor_mobile .feature-box {
    margin-left: 0;
  }
  .content_h2_uyb > h1 {
    font-size: 57px !important;
  }

  .dark .bg-image-new {
    background-image: none !important;
  }
  .dark .didgtal_hero {
    background-image: none !important;
  }

  .section_banner_hero.new_Section_AIdoc.uploaddocs {
    height: auto !important;
    min-height: auto !important;
    border-bottom-left-radius: 0px;
    z-index: 1;
  }
  .benifitlaw-list li {
    width: 100%;
  }
  .margin_bottons_virtyua {
    margin-bottom: 0px !important;
  }
  .bottin_imag_law {
    margin-bottom: -42px !important;
  }

  .didgtal_hero {
    background-image: none !important;
  }
  .section_banner_hero.new_Section_AIdoc {
    border-bottom-left-radius: 0px;
  }
  .upload-steps {
    flex-direction: column;
    align-items: center;
  }

  .upload-step {
    max-width: 100%;
    flex: 1 1 100%;
  }

  .upload-step::after {
    display: none;
  }

  .keyfetupload-item {
    flex-grow: 1;
    flex-shrink: 1;
    flex-basis: calc(100% - 40px);
    max-width: calc(100% - 0px);
  }
  .card_about {
    padding: 30px 15px;
  }
  .blog_data {
    flex-wrap: wrap;
  }
  .new_Section_AIdoc::after {
    width: 90vw;
    height: 67vh;
    right: -10px;
    bottom: -290px;
    display: none;
  }
  .postion_imag_rel {
    position: static;
    width: 100%;
  }
  .new_Section_AIdoc.about_done {
    height: auto !important;
    min-height: auto !important;
    border-radius: 0 !important;
    padding-bottom: 100px;
  }
  .select_about_image {
    margin-top: -100px;
  }
  .padding_adjust {
    padding: 0px 0px;
  }
  .cards-container .card {
    margin: 0px 20px;
  }
  .width_70 {
    width: 100%;
  }
  .width_60 {
    width: 100%;
  }
  .half_width {
    width: 100%;
  }
  .fetuer_section {
    gap: 30px;
    flex-wrap: wrap;
  }
  .hero_seaction_law {
    min-height: auto;

    padding: 120px 0px;
  }
  .video-background {
    height: auto;
    overflow: hidden;
    padding-top: 50px;
  }
  .blg_imag img {
    width: 100%;
  }
  .blog_body {
    padding-top: 150px !important;
  }
  .articelee {
    flex-direction: column;
  }

  .innaer_content .sectin_cate {
    gap: 10px;
    flex-wrap: wrap;
    justify-content: start;
  }
  .sectin_cate {
    gap: 10px;
    flex-wrap: wrap;
    justify-content: space-around;
  }
  .related {
    flex-wrap: wrap !important;
    justify-content: center !important;
  }
  .blg_imag {
    height: 100%;
  }
  .bloh_carde .innaer_content {
    padding: 0px 10px;
    margin-top: 10px;
  }
  .bloh_carde img {
    width: 100%;
  }
  .blg_contant_section h1 {
    font-size: 30px;
  }
  .sectin_cate span {
    font-size: 16px;
  }
  .link_top_section p {
    font-size: 14px !important;
    text-align: center !important;
  }
  .blog-image {
    width: 100%;
  }
  .blog-image img {
    width: 100%;
  }
  .button_moduke {
    width: 90% !important;
  }
  .link_top_section a,
  .link_top_section span {
    font-size: 13px;
  }
  .header_padidng {
    z-index: 1000 !important;
  }
  .MuiDrawer-paper {
    background-color: #000 !important;
  }
  .hero_seaction_law h1 {
    font-size: 42px !important;
    line-height: 48px !important;
  }
  .img_section img {
    width: 90%;
  }
  .section_defualt {
    padding: 40px 0px;
  }
  .mobile_tetxt {
    text-align: center;
  }
  .slider_test {
    flex-direction: column !important;
  }
  .slick-list {
    height: fit-content !important;
  }
  .arrow {
    display: none;
  }
  .contact_fonr {
    padding: 30px 10px !important;
  }
  .text_trie {
    text-align: center;
  }
  .flex_section {
    display: flex;
    justify-content: center;
  }
  .video-wrapper iframe {
    width: 100% !important;
  }
  .img_section img {
    z-index: 111;
  }
  .overvide h2 {
    font-size: 36px;
  }
  .logo_barnd .keyfetupload-item {
    flex-basis: calc(33% - 0px);
    max-width: calc(33% - 10px);
    width: 100% !important;
    max-width: 100% !important;
    flex-basis: calc(100% - 0px);
  }
  section.new_Section_AIdoc.heaight_excahnge {
    height: auto !important;
    min-height: auto !important;
  }
  .section_fixed_height_sedcion {
    padding-top: 173px !important;
  }
  .token-distribution {
    flex-direction: row;
    justify-content: center;
  }
  section.new_Section_AIdoc.heaight_excahnge.height_contact {
    height: auto !important;
  }
  section.new_Section_AIdoc.heaight_excahnge.height_contact.new_hig {
    height: auto !important;
  }
}
@media (max-width: 680px) {
  .container1 .item {
    padding: 25px 80px;
  }
  .br-none br {
    display: none;
  }
  .section_banner_hero.new_Section_AIdoc.height_new {
    height: 100% !important;
    min-height: 0 !important;
    padding-bottom: 0 !important;
  }
}

@media (max-width: 680px) {
  .new_Section_AIdoc::after {
    display: none;
  }
  .container1 .item {
    width: 89%;
  }
  .section_banner_hero.new_Section_AIdoc {
    height: auto !important;
    min-height: auto !important;
    padding-bottom: 90px;
  }
}
@media (max-width: 568px) {
  .container1 .item {
    padding: 20px;
  }
  .overvide h2 {
    font-size: 30px;
  }
  .hero_seaction_law.align-items-start.alter-top-paddings h1 {
    line-height: 40px !important;
  }
  .section_banner_hero.new_Section_AIdoc.about_done.about-us-h {
    height: 100% !important;
    min-height: 100% !important;
  }
}
@media (min-width: 500px) {
  .mt-90 {
    padding-top: 160px;
  }
  .margin_zeeor_mobile .feature-box {
    margin-left: 0;
  }
}
@media (max-width: 480px) {
  .section_banner_hero.new_Section_AIdoc.bg-gray_section-hero.heaight_excahnge.section_fixed_height_sedcion.banner_vierutal_hero.adr-top-padding.adr-margins {
    margin-top: 0 !important;
    padding-top: 40px !important;
  }
  .hero_seaction_law.align-items-start.justify-content-center.fixed_hero_height.adr-top-padding.hero_paddings-top {
    padding-top: 0px !important;
  }
  .adr-top-padding.adr-top-padding_2 {
    padding-top: 0 !important;
    margin-top: 0 !important;
  }
  .section_banner_hero.new_Section_AIdoc.about_done {
    height: auto !important;
    min-height: auto !important;
  }
  .container1 .item {
    width: 100%;
  }
  .new_Section_AIdoc::after {
    display: none;
  }
  .blog_home_banner h1 {
    font-size: 48px;
    line-height: 54px;
  }
  .overvide p {
    font-size: 15px;
  }

  .new_Section_AIdoc h1 {
    font-size: 32px !important;
    line-height: 44px !important;
  }
  .section_banner_hero.new_Section_AIdoc.uploaddocs.bg-image-new {
    height: 100vh !important;
    min-height: 80vh !important;
  }
  .section_banner_hero.new_Section_AIdoc.about_done {
    height: 60vh !important;
    min-height: 70vh !important;
    border-bottom-left-radius: 80px;
  }
  .section_banner_hero.new_Section_AIdoc.height_auto_section {
    height: auto !important;
  }
  .section_banner_hero.new_Section_AIdoc {
    height: 100vh !important;
    min-height: 90vh !important;
    border-bottom-left-radius: 80px;
  }
  .section_fixed_height_sedcion {
    padding-top: 173px !important;
  }

  .dark .blog_home_banner h1 {
    font-size: 48px;
    line-height: 54px;
  }
  .hero_seaction_law {
    min-height: auto;
    padding: 40px 0px;
    padding-top: 130px !important;
  }
  .dark .baner_blog_top {
    margin-top: 0px;
  }
  .dark .hero_seaction_law h1 {
    font-size: 57px;
  }
  .logo_barnd .keyfetupload-item {
    flex-basis: calc(33% - 0px);
    max-width: calc(33% - 10px);
    width: 100% !important;
    max-width: 100%;
    flex-basis: calc(100% - 0px);
  }
  section.new_Section_AIdoc.heaight_excahnge {
    height: auto !important;
    min-height: auto !important;
  }
  .token-distribution {
    flex-direction: row;
    justify-content: center;
  }
  .section_banner_hero.new_Section_AIdoc.uploaddocs.bg-image-new.new-iamge_alter {
    min-height: fit-content !important;
    height: fit-content !important;
  }
  .copywrigt p {
    font-size: 12px;
  }
  .hero_seaction_law.align-items-start.mark_law_title h1 {
    font-size: 28px !important;
    line-height: 35px !important;
  }
  .section_banner_hero.new_Section_AIdoc.height_new {
    height: 100% !important;
    min-height: 0 !important;
  }
  .hero_seaction_law.adr-top-padding {
    padding-top: 0 !important;
  }
}

@media (max-width: 370px) {
  .new_Section_AIdoc::after {
    display: none;
  }
  .section_banner_hero.new_Section_AIdoc {
    height: auto !important;
    min-height: auto !important;
    padding-bottom: 90px;
  }
  .new_Section_AIdoc h1 {
    font-size: 28px !important;
    line-height: 40px !important;
  }
  .hero_seaction_law p {
    font-size: 15px;
  }
  .overvide h2 {
    font-size: 25px;
  }
  .hero_seaction_law h1 {
    font-size: 40px !important;
  }
  .hero_seaction_law h2 {
    font-size: 24px;
  }
  .hero_seaction_law p {
    margin: 20px 0;
  }
  .logo_section_f p {
    font-size: 15px;
  }
  .card_inside_content p {
    font-size: 15px;
  }
  .hero_seaction_law.align-items-start.alter-top-paddings h1 {
    line-height: 32px !important;
    font-size: 28px !important;
  }
  .feature-box h3 {
    font-size: 20px;
  }
  .hero_seaction_law.ai_law_hero .ai_work_heading {
    font-size: 28px !important ;
  }
}
